import { Theme } from '@material-ui/core';

export const typographyStyles = (theme: Theme): any => ({
  ...theme.bodyStyles,
  ...theme.fontFamilies,
  roboto500: {
    fontWeight: 500
  },
  h0: {
    fontSize: '8.438rem',
    fontWeight: 'normal',
    letterSpacing: '0px',
    lineHeight: '8.438rem',
  },
});
