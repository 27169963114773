import React from 'react';
import { useStyles } from './NoticeStyles';
import { Typography } from '../Typography';
import { ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';

interface INotice {
  className?: string;
  heading: string;
  message: string;
  messageType?: 'default' | 'error' | unknown;
}

export const Notice: React.FC<INotice> = ({ className, heading, message, messageType }: INotice): JSX.Element => {
  const classes = useStyles();

  const getNoticeContainerStyles = (messageType: string | unknown, classes: ClassNameMap<string>): string => {
    switch (messageType) {
      case 'default':
        return classes.default;
      case 'error':
        return classes.error;
      default:
        return classes.default;
    }
  };
  return (
    <div className={clsx(classes.noticeContainer, getNoticeContainerStyles(messageType, classes), className)}>
      <Typography className={classes.heading} variant="h6">
        {heading}
      </Typography>
      <Typography variant="body2">{message}</Typography>
    </div>
  );
};

Notice.defaultProps = {
  messageType: 'default',
};

export default Notice;
