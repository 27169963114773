import React, { useContext, useEffect } from 'react';
import {
  StepChecker,
  DocumentTitle,
  ButtonPrimary,
  Typography,
  TextField,
  Divider,
  LinkNavigation,
  DatePicker,
  Address,
} from '../../components/atoms';
import { CoverSummary } from '../../components/molecules';
import { Grid, InputLabel, Box } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './PersonalStyles';
import { StepContext, steps, Step, IStepData } from '../../contexts/StepContext';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import clsx from 'clsx';
import { addYears, format } from 'date-fns';
import { formatDate } from '../../utils/dateFormattingUtils';

export const Review: React.FC = () => {
  const { activeStep, updateActiveStep, data, updateData } = useContext(StepContext);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    updateActiveStep(2);
  }, []);

  const maximumDateOfBirthPicker = addYears(new Date(), -18);

  const schema = yup.object().shape({
    firstName: yup.string().max(35, "This name can't be over 35 letters").required('Please enter your first name'),
    lastName: yup.string().max(35, "This name can't be over 35 letters").required('Please enter your last name'),
    dateOfBirth: yup
      .date()
      .typeError('Please enter a valid date of birth')
      .required('Please enter your date of birth')
      .max(new Date(maximumDateOfBirthPicker), 'You must be over 18'),
    postcodeLookup: yup.object().shape({
      firstLineOfAddress: yup.string().required('Please enter your postcode'),
      secondLineOfAddress: yup.string(),
      thirdLineOfAddress: yup.string(),
      town: yup.string().required('Please select an address'),
      county: yup.string(),
      postcode: yup.string().required('Please enter your postcode'),
    }),
    emailAddress: yup
      .string()
      .max(254, "This email address can't be over 254 characters")
      .email('Please enter a valid email address')
      .required('Please enter your email address'),
    phoneNumber: yup
      .string()
      .transform((value) => (isNaN(value) ? undefined : value))
      .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Let us know your contact number')
      .required('Please enter your mobile number'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      firstName: data.firstName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth ? format(new Date(data.dateOfBirth), 'yyyy/MM/dd') : null,
      postcodeLookup: {
        firstLineOfAddress: data.addressLine1,
        secondLineOfAddress: data.addressLine2,
        thirdLineOfAddress: '',
        town: data.addressLine3,
        county: data.addressLine5,
        postcode: data.postcode,
      },
    },
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const plAddressLine1 = getValues('postcodeLookup.firstLineOfAddress');
  const plAddressLine2 = getValues('postcodeLookup.secondLineOfAddress');
  const plAddressLine3 = getValues('postcodeLookup.thirdLineOfAddress');
  const plAddressLine4 = getValues('postcodeLookup.town');
  const plAddressLine5 = getValues('postcodeLookup.county');
  const plPostcode = watch('postcodeLookup.postcode');

  const onSubmit = (stepData: IStepData) => {
    const dateOfBirthForSubmission = getValues('dateOfBirth');
    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...stepData,
      ...getValues(),
      addressLine1: plAddressLine1,
      addressLine2: plAddressLine2,
      addressLine3: plAddressLine4,
      addressLine4: '',
      addressLine5: plAddressLine5,
      postcode: plPostcode,
      dateOfBirth: format(new Date(formatDate(dateOfBirthForSubmission)), 'yyyy/MM/dd'),
    });
    history.push(steps[Step.IMPORTANTINFO].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({
      ...data,
    });
    const path = data.quoteHash
      ? `${steps[Step.COVER_OPTIONS].url}/${data.quote}/${data.quoteHash}`
      : `${steps[Step.COVER_OPTIONS].url}/${data.quote}`;
    history.push(path);
  };

  // Prevent onKeyPress form submission
  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.key === 'Enter' && e.preventDefault();
  };

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Review`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <LinkNavigation onClick={handleBack}>Back</LinkNavigation>

            <Box>
              <Box className={clsx(classes.maxWidth90, 'pt2')}>
                <CoverSummary
                  // firstName="First name"
                  tripType={data.coverType}
                  coverPrice={data.coverPrice}
                  policyStartDate={data.policyStartDate}
                  policyEndDate={data.policyEndDate}
                />
              </Box>
              <Typography className="pt2" variant="h2">
                Let us know a few details.
              </Typography>
              <Typography className="pt2" variant="h3">
                About you
              </Typography>

              <InputLabel className="inputLabel pt2" htmlFor="firstName">
                First name
              </InputLabel>
              <TextField
                className={clsx(classes.inputFields, 'pt1')}
                id="firstName"
                name="firstName"
                control={control}
                defaultValue={data.firstName}
                onKeyPress={handleOnKeyPress}
                error={errors.firstName ? true : false}
              />
              <Typography className="fieldError">{errors.firstName?.message}</Typography>

              <InputLabel className="inputLabel pt2 pb1" htmlFor="lastName">
                Last name
              </InputLabel>
              <TextField
                className={classes.inputFields}
                id="lastName"
                name="lastName"
                control={control}
                defaultValue={data.lastName}
                onKeyPress={handleOnKeyPress}
                error={errors.lastName ? true : false}
              />
              <Typography className="fieldError">{errors.lastName?.message}</Typography>

              <InputLabel className="inputLabel pt2 pb1" htmlFor="dateOfBirth">
                Date of birth
              </InputLabel>
              <Box>
                <DatePicker
                  id="dateOfBirth"
                  name="dateOfBirth"
                  className={classes.dobField}
                  defaultValue={data.dateOfBirth}
                  maxDate={maximumDateOfBirthPicker}
                  control={control}
                  disableFuture={true}
                />
                <Typography className="fieldError">{errors.dateOfBirth?.message}</Typography>
              </Box>
            </Box>

            <Box className="pt3">
              <Typography className="pb1" variant="h3">
                Your address
              </Typography>

              <Address
                addressLine1={plAddressLine1}
                addressLine2={plAddressLine2}
                addressLine3={plAddressLine3}
                addressLine4={plAddressLine4}
                addressLine5={plAddressLine5}
                postcode={plPostcode}
                plName="postcodeLookup.postcode"
                plPostcode={plPostcode}
                setValue={setValue}
                trigger={trigger}
                control={control}
              />

              {errors.postcodeLookup && (
                <Typography className="fieldError">
                  {errors.postcodeLookup?.firstLineOfAddress
                    ? errors.postcodeLookup?.firstLineOfAddress.message
                    : errors.postcodeLookup?.town
                    ? errors.postcodeLookup?.town.message
                    : errors.postcodeLookup?.postcode
                    ? errors.postcodeLookup.postcode.message
                    : ''}
                </Typography>
              )}
            </Box>

            <Box>
              <Typography variant="h2" className="mt3">
                Your contact details
              </Typography>
              <Typography className="pt1">
                We&apos;ll only use your email address and number if we need to get in touch about your policy.
              </Typography>

              <Box className="pt2">
                <InputLabel className="inputLabel" htmlFor="emailAddress">
                  Email address
                </InputLabel>
                <TextField
                  className={clsx(classes.inputFields, 'pt1')}
                  id="emailAddress"
                  name="emailAddress"
                  control={control}
                  defaultValue={data.emailAddress}
                  onKeyPress={handleOnKeyPress}
                  error={errors.emailAddress ? true : false}
                />
                <Typography className="fieldError">{errors.emailAddress?.message}</Typography>
              </Box>

              <Box className="pt2">
                <InputLabel className="inputLabel" htmlFor="phoneNumber">
                  Mobile number
                </InputLabel>
                <TextField
                  className={clsx(classes.inputFields, 'pt1')}
                  id="phoneNumber"
                  name="phoneNumber"
                  control={control}
                  defaultValue={data.phoneNumber}
                  onKeyPress={handleOnKeyPress}
                  error={errors.phoneNumber ? true : false}
                />
                <Typography className="fieldError">{errors.phoneNumber?.message}</Typography>
              </Box>
            </Box>

            <Divider className="divider mt2"></Divider>

            <Box className={classes.actionButton}>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </Box>
          </form>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Review;
