import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    yourVehiclesContainer: {
      border: `2px solid ${theme.palette.dark[500]}`,
      maxWidth: '40rem',
      marginTop: '1rem',
      '& div': {
        marginBottom: '0.5rem',
      },
      '& div:last-child': {
        marginBottom: '2rem',
      },
    },
    vehicleLookupGrid: {
      padding: '1rem 1.75rem',
    },
    bottomHighlight: {
      padding: '0rem 1.25rem',
      backgroundColor: theme.palette.light[100],
    },
    field: {
      ...theme.form.labels?.selectFieldSmall.label,
      fontSize: '1.125rem',
      fontWeight: 'normal',
    },
    fieldValue: {
      ...theme.form.labels?.selectFieldSmall.label,
    },
    vehicleDetails: {
      ...theme.form.labels?.selectFieldSmall.label,
      fontWeight: 400,
    },
  }),
  { index: 1 },
);
