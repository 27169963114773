import React, { Dispatch, SetStateAction } from 'react';
import { useStyles } from '../PostcodeLookupStyles';
import { Typography, ButtonPrimary, ButtonSecondary } from '../../../atoms';
import { List } from '@material-ui/core';

interface IPostcodeLookupErrorModalBody {
  showErrorModal: { visible: boolean; errorMessage: string };
  setShowErrorModal: Dispatch<SetStateAction<{ visible: boolean; errorMessage: string }>>;
  postcode: string;
}

export const PostcodeLookupErrorModalBody: React.FC<IPostcodeLookupErrorModalBody> = ({
  showErrorModal,
  setShowErrorModal,
  postcode,
}: IPostcodeLookupErrorModalBody): JSX.Element => {
  const handleCancel = (): void => setShowErrorModal({ visible: false, errorMessage: '' });
  const classes = useStyles();

  return (
    <div className={classes.resultsContainer}>
      <Typography className={classes.information}>Searching for {postcode}</Typography>
      <Typography className={classes.information}>{showErrorModal.errorMessage}</Typography>
      <List component="nav" aria-label="empty list" className={classes.list}></List>
      <div className={classes.actionButtonsContainer}>
        <ButtonPrimary disabled={true}>Select</ButtonPrimary>
        <ButtonSecondary onClick={handleCancel}>Cancel</ButtonSecondary>
      </div>
    </div>
  );
};

export default PostcodeLookupErrorModalBody;
