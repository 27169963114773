import React from 'react';
import { useStyles } from './CoverSummaryStyles';
import { Typography, TrustBox } from '../../atoms';
import { Box, Grid } from '@material-ui/core';
import { parse, format } from 'date-fns';
import { getDateFormat } from '../../../utils/dateFormattingUtils';
import clsx from 'clsx';

interface ICoverSummary {
  coverPrice: number | null;
  policyStartDate: Date | string | null | undefined;
  policyEndDate: Date | string | null | undefined;
  tripType: string;
}

export const CoverSummary: React.FC<ICoverSummary> = ({
  coverPrice,
  policyStartDate,
  policyEndDate,
  tripType,
}: ICoverSummary): JSX.Element => {
  const classes = useStyles();
  const regex = /-/g;
  const startDateWithExpectedSeparators = policyStartDate ? policyStartDate.toString().replace(regex, '/') : null;
  const formattedPolicyStartDate = startDateWithExpectedSeparators
    ? parse(startDateWithExpectedSeparators, getDateFormat(startDateWithExpectedSeparators), new Date())
    : null;
  const psd = formattedPolicyStartDate ? format(new Date(formattedPolicyStartDate), 'dd/MM/yyyy') : '';

  const endDateWithExpectedSeparators = policyEndDate ? policyEndDate.toString().replace(regex, '/') : null;
  const formattedPolicyEndDate = endDateWithExpectedSeparators
    ? parse(endDateWithExpectedSeparators, getDateFormat(endDateWithExpectedSeparators), new Date())
    : null;
  const ped = formattedPolicyEndDate ? format(new Date(formattedPolicyEndDate), 'dd/MM/yyyy') : '';

  const coverTypeLong = tripType.includes('Annual') ? 'annual multi-trip' : 'single trip';

  return (
    <Box className={classes.coverSummaryContainer}>
      {/* <div className="pb2">
        <Typography className={clsx(classes.headings, classes.overflowHidden)} variant="h2">Hi {firstName}.</Typography>
      </div> */}
      <div className="pb2 pt1">
        <Typography className={classes.headings} variant="h2">
          Your {coverTypeLong ? coverTypeLong : ''} cover will cost{' '}
          <span className={classes.greenHighlight}>£{coverPrice ? coverPrice.toFixed(2) : ''}</span>.
        </Typography>
        <Typography className={classes.subHeadings} variant="h3">
          Includes Insurance Premium Tax where applicable.
        </Typography>
      </div>
      <div className="pt1">
        <Typography className={classes.headings} variant="h2">
          It&apos;ll start on <span className={classes.greenHighlight}>{psd ? psd : ''}</span>
        </Typography>
        <Typography className={classes.headings} variant="h2">
          and end on <span className={classes.greenHighlight}>{ped ? ped : ''}</span>.
        </Typography>
      </div>
      <Box className={clsx(classes.trustBoxContainer, classes.mobileBreakPoint)}>
        <Box className="mb3 pb2">
          <Typography className={classes.headings} variant="h2">
            Whatever happens, we&apos;ll be there to help.
          </Typography>
        </Box>
        <TrustBox className={classes.trustBox} theme="dark" />
        {/* mobile layout */}
      </Box>
      <Grid>
        <Box className={clsx(classes.trustBoxContainer, classes.desktopBreakpoint)}>
          <Grid item md={6}>
            <Box className="mb3">
              <Typography className={clsx(classes.headings, 'pb2')} variant="h2">
                Whatever happens, we&apos;ll be there to help.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <TrustBox className={classes.trustBox} theme="dark" />
            {/* desktop layout */}
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};

export default CoverSummary;
