import React, { useEffect } from 'react';

interface IDocumentTitle {
  title: string
}

const DocumentTitle = ({ title }: IDocumentTitle): JSX.Element => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return <></>;
};

export default DocumentTitle;
