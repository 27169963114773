import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    heading: {
      marginTop: '4rem',
      marginBottom: '3rem',
      maxWidth: '35rem',
    },
    gridContainer: {
      position: 'relative',
    },
    gridMain: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: '2rem',
      },
    },
    lineHeight: {
      lineHeight: '2rem',
    },
  }),
  { index: 1 },
);