import React, { Dispatch, SetStateAction } from 'react';
import { useStyles } from './RadioButtonTabsStyles';
import PropTypes from 'prop-types';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { Typography } from '../../atoms';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

interface ITabPanel {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: ITabPanel) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

interface IRadioButtonTab {
  radioBtnSelectedIndex: number;
  classes: any;
  value: number;
  heading: string;
  subHeading: string;
  setRadioBtnSelectedIndex: Dispatch<SetStateAction<number>>;
  onClick?: () => void;
}

const RadioButtonTab = ({
  radioBtnSelectedIndex,
  classes,
  value,
  heading,
  subHeading,
  setRadioBtnSelectedIndex,
}: IRadioButtonTab) => {
  const selected = radioBtnSelectedIndex === value ? true : false;
  const selectedClass = selected ? classes.radioTabActive : classes.radioTabInActive;
  const handleChange = (newValue: number) => setRadioBtnSelectedIndex(newValue);

  return (
    <div className={clsx(classes.radioTabContainer, selectedClass, classes.focus)} onClick={() => handleChange(value)}>
      <FormControlLabel
        className={classes.formControlLabel}
        value={value}
        control={
          <Radio
            className={classes.radioBtn}
            color="primary"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
          />
        }
        label={
          <div className={classes.radioLabelContainer}>
            <h3 className={clsx(classes.body.body1, classes.radioHeading)}>{heading}</h3>
            <p className={clsx(classes.body.body5, classes.radioHeading)}>{subHeading}</p>
          </div>
        }
      />
    </div>
  );
};

interface IRadioButtonTabsData {
  heading: string;
  subHeading: string;
  body: JSX.Element | string;
}

interface IRadioButtonTabs {
  data: Array<IRadioButtonTabsData>;
}

const RadioButtonTabs: React.FC<IRadioButtonTabs> = ({ data }: IRadioButtonTabs) => {
  const classes = useStyles();
  const [radioBtnSelectedIndex, setRadioBtnSelectedIndex] = React.useState(0);

  return (
    <div className={classes.root}>
      <RadioGroup value={radioBtnSelectedIndex} className={classes.radioGroup}>
        {data.map((entry: IRadioButtonTabsData, index: number) => {
          return (
            <RadioButtonTab
              key={index}
              radioBtnSelectedIndex={radioBtnSelectedIndex}
              setRadioBtnSelectedIndex={setRadioBtnSelectedIndex}
              classes={classes}
              value={index}
              heading={entry.heading}
              subHeading={entry.subHeading}
            />
          );
        })}
      </RadioGroup>
      <div className={classes.tabPanelContainer}>
        {data.map((entry: IRadioButtonTabsData, index: number) => {
          return (
            <TabPanel key={index} value={radioBtnSelectedIndex} index={index}>
              {entry.body}
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
};

export default RadioButtonTabs;
