import React from 'react';
import { Divider as MuiDivider, DividerProps } from '@material-ui/core';
import { useStyles } from './DividerStyles';
import clsx from 'clsx';

export const Divider: React.FC<DividerProps> = ({ className }: DividerProps): JSX.Element => {
  const classes = useStyles();
  return <MuiDivider classes={{ root: clsx(classes.root, className) }} />;
};

export default Divider;
