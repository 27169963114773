import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    appBar: {
      backgroundColor: theme.palette.dark[900],
      boxShadow: 'none',
    },
    container: {
      maxWidth: '1280px',
      padding: '1.5rem 1.2rem',
    },
    greenFlagLogoGridContainer: {
      display: 'flex',
      alignSelf: 'flex-start',
      justifyContent: 'space-between',
      '& img': {
        maxWidth: '250px',
        maxHeight: '50px',
        '@media (max-width: 400px)': {
          width: '180px',
        },
      },
    },
    contactGridContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'baseline',
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '1.5rem',
      },
    },
    helpButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    dNoneMobile: {
      '@media (max-width: 1279px)': {
        display: 'none',
      },
    },
    dNoneDesktop: {
      '@media (min-width: 1280px)': {
        display: 'none',
        margin: '1rem 0',
      },
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    dOnlyMobile: {
      '@media (min-width: 426px)': {
        display: 'none',
      },
    },
  }),
  { index: 1 },
);
