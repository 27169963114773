import React, { useContext, useEffect, useState } from 'react';
import {
  StepChecker,
  DocumentTitle,
  Typography,
  LinkNavigation,
  Accordion,
  ButtonSecondary,
  Checkbox,
  Divider,
  ButtonPrimary,
} from '../../components/atoms';
import { Grid, Box, FormControl } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './ImportantInfoStyles';
import { StepContext, steps, Step } from '../../contexts/StepContext';
import { Modal } from '../../components/molecules';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import importantInfoSchema from './ImportantSchema';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as yup from 'yup';
import clsx from 'clsx';
import { getPremiumType, getProviderID, isMobile } from '../../utils/paymentUtils';
import { format } from 'date-fns';
import { convertDateToDlgFormat, convertDateToDisplayFormat } from '../../utils/dateFormattingUtils';
import axios from 'axios';
import Cookies from 'js-cookie';

export const ImportantInfo: React.FC = () => {
  const { activeStep, updateActiveStep, data, updateData } = useContext(StepContext);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [stripeUrl, setStripeUrl] = useState('');
  const history = useHistory();
  const classes = useStyles();

  const formattedPolicyStartDate = data.policyStartDate ? convertDateToDlgFormat(data.policyStartDate.toString()) : '';
  const formattedPolicyEndDate = data.policyEndDate ? convertDateToDlgFormat(data.policyEndDate.toString()) : '';
  const startDateForBackend = format(new Date(formattedPolicyStartDate), 'yyyy/MM/dd');
  const endDateForBackend = format(new Date(formattedPolicyEndDate), 'yyyy/MM/dd');
  const startDateForFrontend = format(new Date(formattedPolicyStartDate), 'dd/MM/yyyy');
  const endDateForFrontend = format(new Date(formattedPolicyEndDate), 'dd/MM/yyyy');

  const formattedDateOfBirth = data.dateOfBirth ? convertDateToDlgFormat(data.dateOfBirth.toString()) : '';
  const dobForBackend = format(new Date(formattedDateOfBirth), 'yyyy/MM/dd');

  useEffect(() => {
    updateActiveStep(3);
  }, []);

  const schema = yup.object().shape({
    meetsVehicleCriteria: yup.bool().oneOf([true], 'Checkbox must be checked to proceed'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    trigger,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      meetsVehicleCriteria: false,
    },
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async () => {
    const paymentObject = {
      Order: {
        customer: {
          title: data.title,
          first_name: data.firstName,
          last_name: data.lastName,
          date_of_birth: dobForBackend,
          address: {
            house: data.addressLine1,
            street: data.addressLine2,
            town: data.addressLine3,
            postcode: data.postcode,
          },
          telephone: data.phoneNumber,
          email: data.emailAddress,
        },
        brand_no_contact: false,
        vehicle: {
          registration: data.registrationNumber,
          make: data.vehicleMake,
          model: data.vehicleModel,
          year_of_manufacture: new Date().getFullYear() - +data.vehicleAge,
        },
        continuous: 'false',
        start_date: startDateForBackend,
        end_date: endDateForBackend,
        quote_id: data.quote,
        price_pence: data.coverPrice ? Math.round(data.coverPrice * 100) : 0,
        vehicle_age_yrs: data.vehicleAge,
        cover_options: {
          base_option: data.baseOption,
          additional_options: data.additionalOption,
          // additional_options: buildAdditionalOptionsJsonNameArray(data.productName.split(' + '))
        },
        /* Comparison Site Analytics */
        origin_site: data.source,
        is_mobile: isMobile(),
        product_name: data.productName,
        // Probably these can be derived in the backend
        Cancellation: 0,
        Premium: data.coverPrice,
        PremiumType: getPremiumType(data.productName),
        ProviderId: getProviderID(data.source),
      },
    };

    try {
      const { data: response } = await axios.post(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_CHECKOUT_ENDPOINT}`,
        paymentObject,
      );

      setStripeUrl(response.responseBody.checkoutUrl);

      Cookies.remove('GFSessionControl', { path: ',' });
      Cookies.set('GFSessionControl', response.responseBody.hash , {
        expires: 15 / (24 * 60), // 15 minutes
        path: '/',
        secure: true,
      });

      setPaymentModalOpen(true);
    } catch (err) {
      history.push('/failedpayment');
    }

    // history.push(steps[Step.PAYMENT].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({
      ...data,
    });
    history.push(steps[Step.PERSONAL].url);
  };

  const getDocument = (filePath: string) => window.open(filePath);

  const {
    rightToCancel,
    complaints,
    financialCompensation,
    informationUsage,
    englishLawApplies,
    PaymentModalData,
  } = importantInfoSchema;

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Review`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <LinkNavigation onClick={handleBack}>Back</LinkNavigation>
            <Typography className="pt2" variant="h1">
              All the important stuff.
            </Typography>
            <Typography className="pt3" variant="h2">
              Details about your policy
            </Typography>
            <Typography className="pt3" variant="h3">
              You and your vehicle
            </Typography>

            <Grid container className="pt2">
              <Grid item xs={6} md={3}>
                <Typography variant="body1" className={classes.bold}>Policyholder:</Typography>
              </Grid>
              <Grid item xs={6} md={9}>
                <Typography variant="body1">{data.firstName} {data.lastName}</Typography>
              </Grid>

              <Grid item xs={6} md={3} className="pt1">
                <Typography variant="body1" className={classes.bold}>Address:</Typography>
              </Grid>
              <Grid item xs={6} md={9} className="pt1">
                <Typography variant="body1">{data.addressLine1}, {data.addressLine3}, {data.postcode}</Typography>
              </Grid>

              <Grid item xs={6} md={3} className="pt1">
                <Typography variant="body1" className={classes.bold}>Date of birth:</Typography>
              </Grid>
              <Grid item xs={6} md={9} className="pt1">
                <Typography variant="body1">{convertDateToDisplayFormat(data.dateOfBirth.toString())}</Typography>
              </Grid>

              <Grid item xs={6} md={3} className="pt1">
                <Typography variant="body1" className={classes.bold}>Registration number:</Typography>
              </Grid>
              <Grid item xs={6} md={9} className="pt1">
                <Typography variant="body1">{data.registrationNumber}</Typography>
              </Grid>

              <Grid item xs={6} md={3} className="pt1">
                <Typography variant="body1" className={classes.bold}>Vehicle details:</Typography>
              </Grid>
              <Grid item xs={6} md={9} className="pt1">
                <Typography variant="body1">{data.vehicleMake} {data.vehicleModel}, {new Date().getFullYear() - data.vehicleAge} </Typography>
              </Grid>
            </Grid>
            <Divider className={clsx(classes.reponsiveWidth, 'divider mt2')}></Divider>

            <Typography className="pt2" variant="h3">
              Your cover
            </Typography>

            <Grid container className="pt2">
              <Grid item xs={6} md={3} className="pt1">
                <Typography variant="body1" className={classes.bold}>Your cover:</Typography>
              </Grid>
              <Grid item xs={6} md={9} className="pt1">
                <Typography variant="body1">{data.coverType}{data.additionalOption.includes('Towing')?', Towing a Caravan':''}</Typography>
              </Grid>

              <Grid item xs={6} md={3} className="pt1">
                <Typography variant="body1" className={classes.bold}>Start date:</Typography>
              </Grid>
              <Grid item xs={6} md={9} className="pt1">
                <Typography variant="body1">{startDateForFrontend}</Typography>
              </Grid>

              <Grid item xs={6} md={3} className="pt1">
                <Typography variant="body1" className={classes.bold}>End date:</Typography>
              </Grid>
              <Grid item xs={6} md={9} className="pt1">
                <Typography variant="body1">{endDateForFrontend}</Typography>
              </Grid>
            </Grid>
            
            <Divider className={clsx(classes.reponsiveWidth, 'divider mt2')}></Divider>

            <Typography className="pt3" variant="h2">
              Important statements
            </Typography>
            <Typography className={clsx(classes.reponsiveWidth, 'pt2')}>
              Please make sure all the information you have told us is correct and your vehicle meets the criteria
              below. Your price is worked out using this information and if anything you’ve told us is wrong, you might
              not be covered.
            </Typography>
            <Typography className="pt2">Your vehicle(s) must meet these criteria:</Typography>

            <Box className={clsx(classes.reponsiveWidth, classes.importantStatementsList)}>
              <ol className="pl1">
                <li className="pl1">
                  <Typography>
                    The vehicle is a car, light van, motorhome or motorbike and is privately registered in the UK.
                  </Typography>
                </li>
                <li className="pl1">
                  <Typography>
                    The vehicle must be no more than 7 metres in length (apart from a tow bar or coupling device), 3
                    metres tall, 2.55 metres wide and weigh no more than 3,500 metric tons (3,500kg) in total, including
                    any load being carried.
                  </Typography>
                </li>
                <li className="pl1">
                  <Typography>
                    The vehicle meets any legal requirements and driving laws that apply including having valid tax,
                    insurance and a MOT.
                  </Typography>
                </li>
                <li className="pl1">
                  <Typography>
                    If you&apos;re towing a caravan or trailer it will be covered under Sections 2 and 4 if the
                    appropriate extra Premium has been paid, provided:
                  </Typography>
                  <ul className="pl1">
                    <li className="pl1">
                      <Typography>It fits a standard 50mm tow ball; and</Typography>
                    </li>
                    <li className="pl1">
                      <Typography>
                        It does not exceed (including any load carried) the following gross vehicle weight: 3.5 tonnes
                        and external dimensions: 7 metres in length (excluding any coupling device and tow bar), 3
                        metres in height and 2.3 metres in width; and
                      </Typography>
                    </li>
                    <li className="pl1">
                      <Typography>
                        The weight of the caravan or trailer when loaded is not more than the kerb weight of the Insured
                        Vehicle; and
                      </Typography>
                    </li>
                    <li className="pl1">
                      <Typography>It is of standard make; and</Typography>
                    </li>
                    <li className="pl1">
                      <Typography>All caravans and trailers carry a serviceable spare wheel and tyre.</Typography>
                    </li>
                  </ul>
                </li>
              </ol>
            </Box>
            <Box className={clsx(classes.reponsiveWidth, 'pt2')}>
              <Accordion heading={rightToCancel.heading} body={rightToCancel.body} />
              <Accordion heading={complaints.heading} body={complaints.body} />
              <Accordion heading={financialCompensation.heading} body={financialCompensation.body} />
              <Accordion heading={informationUsage.heading} body={informationUsage.body} />
              <Accordion heading={englishLawApplies.heading} body={englishLawApplies.body} />
            </Box>
            <Box className={clsx(classes.reponsiveWidth, 'pt1')}>
              <Typography variant="h3">Insurance product information document (IPID)</Typography>
              <Typography className="py2">
                This is a summary of the main things that your policy will and won&apos;t cover. Please note it&apos;s
                not personalised to you and doesn&apos;t contain everything.
              </Typography>
              <ButtonSecondary
                onClick={() => getDocument('pdfs/European-IPID.pdf')}
                endIcon={<GetAppIcon fontSize="large" />}
              >
                Download PDF (3.2MB)
              </ButtonSecondary>
              <Typography className="pt2">
                This product meets the demands and needs of those who wish to ensure that they have assistance if their
                vehicle breaks down and they are not in the UK.
              </Typography>
            </Box>
            <Box className={clsx(classes.reponsiveWidth, 'pt2')}>
              <FormControl component="fieldset">
                <Checkbox
                  name="meetsVehicleCriteria"
                  label="I confirm that my vehicle meets all the criteria listed above."
                  watch={watch}
                  control={control}
                  trigger={trigger}
                  error={errors.meetsVehicleCriteria ? true : false}
                  color="primary"
                />
              </FormControl>
              <Typography className="fieldError">{errors.meetsVehicleCriteria?.message}</Typography>
            </Box>

            <Divider className={clsx(classes.reponsiveWidth, 'divider mt4')}></Divider>

            <Box className={clsx(classes.actionButton, 'pt1')}>
              <ButtonPrimary type="submit">Continue to payment</ButtonPrimary>

              <Modal
                open={paymentModalOpen}
                setOpen={setPaymentModalOpen}
                componentType="fragment"
                heading={PaymentModalData.heading}
                body={PaymentModalData.body}
                hasCallUsLink={false}
                hasFooter={true}
                buttonUrl={stripeUrl}
              />
            </Box>
          </form>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default ImportantInfo;
