import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      ...theme.typography.button,
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'center',
      color: theme.palette.dark[700],
      textTransform: 'none',
      minHeight: '2.5rem',
      padding: '1rem 0.5rem',
      '& svg': {
          marginRight: '0.25rem'
      },
    },
    underline: {
      textDecoration: 'underline',
      textDecorationThickness: '2px',
      textUnderlinePosition: 'under',
      textDecorationColor: theme.palette.utility.linkSecondaryUnderlineEnabled,

      '&:hover': {
        cursor: 'pointer',
        textDecorationThickness: '2px',
        textDecorationColor: theme.palette.dark[900],
      },
      '&:focus': {
        textDecorationThickness: '2px',
        backgroundColor: theme.palette.utility.linkFocusBg,
        textDecorationColor: theme.palette.dark[900],
      },
    },
    disabled: {
      ...theme.typography.button,
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'center',
      textTransform: 'none',
      color: theme.palette.utility.linkDisabledText,
      borderBottom: `2px solid ${theme.palette.utility.linkDisabledUnderline}`,
      paddingBottom: '2px',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  }),
  { index: 1 },
);
