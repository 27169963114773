import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  dFlex: {
    display: 'flex',
  },
  icons: {
    color: theme.palette.green[800],
    fontSize: '2rem',
    '@media (min-width: 767px)': {
      fontSize: '3.5rem',
    },
  },
  iconMaxW: {
    maxWidth: '11rem',
  },
  headings: {
    ...theme.bodyStyles.body1,
    fontWeight: 500,
    '@media (min-width: 767px)': {
      ...theme.bodyStyles.body0,
    },
  },
  yourNextStepsHeading: {
    marginBottom: '3rem',
    '@media (max-width: 767px)': {
      marginBottom: '1rem',
    },
  },
  backgroundCard: {
    backgroundColor: 'white',
    borderRadius: '15px',
  },
  responsivePadding: {
    '@media (min-width: 767px)': {
      paddingLeft: '2rem',
    },
  },
  responsiveIconPadding: {
    paddingLeft: '2rem',
    '@media (min-width: 767px)': {
      paddingLeft: '1rem',
    },
  },
  badgeStyles: {
    paddingLeft: '2rem',
    paddingTop: '1rem',
    '@media (min-width: 767px)': {
      paddingLeft: '1rem',
    },
  },
}));
