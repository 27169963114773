import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  () => ({
    yourVoluntaryExcessContainer: {
      maxWidth: '32rem',
      marginBottom: '3rem',
    },
  }),
  { index: 1 },
);
