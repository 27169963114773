import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      color: theme.palette.dark[400],
      height: '2px',
    },
  }),
  { index: 1 },
);
