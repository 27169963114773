import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  btnSecondaryLight: {
    '&:focus': {
      border: `2px solid ${theme.palette.green[500]}`,
      boxShadow: `0px 1px 4px 0px ${theme.palette.green.main}`,

      '& .MuiSvgIcon-root': {
        color: theme.palette.dark[900],
      },
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.utility.btnDisabledLightBg,
      border: `2px solid transparent`,
      '&.MuiButton-text': {
        color: theme.palette.dark[800],
        opacity: 0.5,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.dark[900],
      },
    },
  },
}));
