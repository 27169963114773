import { Box, Grid, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { LinkPrimary, TextField, ButtonPrimary, Typography } from '../../../atoms';
import { useStyles } from './VehicleLookUpStyles';
import { VehicleInformation } from '../VehicleInformation/VehicleInformation.component';
import axios from 'axios';
import clsx from 'clsx';

export interface IVLBackendItem {
  make: string;
  model: string;
  year: string;
}
interface IVehicleData {
  vlName: string;
  registrationNumber: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string | number;
  vlRegistrationNumber: string;
  setValue: any;
  trigger: any;
  control: any;
  onClick?: (() => void) | undefined;
}

export const VehicleLookUp: React.FC<IVehicleData> = ({
  registrationNumber,
  vehicleMake,
  vehicleModel,
  vehicleYear,
  vlRegistrationNumber,
  vlName,
  setValue,
  control,
  onClick,
}: IVehicleData): JSX.Element => {
  const [showLookup, setShowLookup] = useState(false);
  const [lookupComplete, setLookupComplete] = useState(false);
  const [showManualFields, setShowManualFields] = useState(false);

  const [informationIsCorrect, setInformationIsCorrect] = useState<boolean | undefined>(undefined);
  const classes = useStyles();

  const [showErrorMessage, setShowErrorMessage] = useState({ visible: false, errorMessage: '' });

  const handleVLookupSubmit = async (onClick: (() => void) | undefined): Promise<void> => {
    if (onClick !== undefined) onClick(); // Handle onClick being passed down as a component prop
    setLookupComplete(false);
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_VEHICLE_LOOKUP_URL}`,
        {
          vrm: registrationNumber
            .replace(/[^0-9a-z\s]/gi, '')
            .replace(/ /g, '')
            .trim(),
        },
      );

      if (data.status == 200) {
        const calculateVehicleAge = new Date().getFullYear() - data.data.year;
        if (calculateVehicleAge > 16) {
          setShowErrorMessage({
            visible: true,
            errorMessage:
              "Wow that's a classic you have there! But unfortunately we don't cover cars 16 or older for short term Europe trips. This means we won't be able to help you in this vehicle",
          });
          setShowManualFields(false);
        } else {
          setShowErrorMessage({ visible: false, errorMessage: '' });
          setValue('vehicleLookUp.vehicleMake', data.data.make);
          setValue('vehicleLookUp.vehicleModel', data.data.model);
          setValue('vehicleLookUp.vehicleYear', data.data.year);
          setValue(
            'vehicleLookUp.registrationNumber',
            registrationNumber
              .replace(/[^0-9a-z\s]/gi, '')
              .replace(/ /g, '')
              .trim()
              .toUpperCase(),
          );
          setLookupComplete(true);
        }
      } else {
        setShowManualFields(true);
      }
    } catch {
      setLookupComplete(false);
      setShowErrorMessage({
        visible: true,
        errorMessage:
          "Sorry, we couldn't find this vehicle’s details. Please try again or manually enter your vehicle details below",
      });
      setShowManualFields(true);
      setInformationIsCorrect(false);
    }
  };

  const handleShowVehicleLookup = () => {
    setValue('vehicleLookUp.registrationNumber', '');
    setValue('vehicleLookUp.vehicleMake', '');
    setValue('vehicleLookUp.vehicleModel', '');
    setValue('vehicleLookUp.vehicleYear', '');
    setShowLookup(true);
    setShowErrorMessage({ visible: false, errorMessage: '' });
    setValue('vehicleLookUp.hasItBeenClicked', false);
  };

  // Prevent onKeyPress form submission
  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.key === 'Enter' && e.preventDefault();
  };

  const saveManualFields = (data = []): void => {
    setValue('vehicleLookUp.registrationNumber', registrationNumber.toUpperCase());
    setValue('vehicleLookUp.vehicleMake', data['make']);
    setValue('vehicleLookUp.vehicleModel', data['model']);
    setValue('vehicleLookUp.vehicleYear', data['year']);
    setValue('vehicleLookUp.hasItBeenClicked', true);
    setLookupComplete(true); // this = true is the nice result box after successful lookup
    // setShowLookup(false); // this == false means the input box and button is hidden, if its true then it will show the lookup fields.
  };

  useEffect(() => {
    if (vlRegistrationNumber === '') {
      setShowLookup(true);
    } else if (vehicleMake === '') {
      setShowLookup(true);
    } else {
      setShowLookup(false);
      setValue('vehicleLookUp.hasItBeenClicked', true);
    }
  }, [lookupComplete]);

  if (showLookup) {
    return (
      <>
        <InputLabel className={classes.registrationNumberLabel} htmlFor="registrationNumber">
          Registration number
        </InputLabel>
        <Box className={classes.lookupContainer}>
          <TextField
            placeholder="YOUR REG"
            id="registrationNumber"
            name={vlName}
            control={control}
            onKeyPress={handleOnKeyPress}
          />

          <ButtonPrimary
            className={classes.lookupBtn}
            name="vehicleLookUp"
            disabled={vlRegistrationNumber === undefined || vlRegistrationNumber.trim() === ''}
            onClick={() => handleVLookupSubmit(onClick)}
          >
            {'Find your vehicle' || 'Look Up'}
          </ButtonPrimary>
        </Box>

        <div>
          {showErrorMessage.visible ? (
            <Typography className={clsx(classes.maxWidth30, 'fieldErrorLong pt1')}>
              {showErrorMessage.errorMessage}
            </Typography>
          ) : (
            <></>
          )}
        </div>

        {showManualFields ? (
          <VehicleInformation
            entry={'vehicleLookUp'}
            saveFields={saveManualFields}
            informationIsCorrect={informationIsCorrect}
            setInformationIsCorrect={setInformationIsCorrect}
          />
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    //this is information display on a successful lookup
    <Box className={classes.vehicleLookUpContainer}>
      <Grid container className={classes.vehicleLookUpGrid}>
        <Grid className={classes.regBoxContainer} item xs={12} sm={3}>
          <div className={classes.regBox}>
            <Typography className="textCenter" variant="h3">
              {registrationNumber}
            </Typography>
          </div>
        </Grid>
        <Grid className={classes.responsivePadding} item xs={12} sm={9}>
          <Typography variant="body1" className="fw-500">
            {vehicleMake !== '' ? `${vehicleMake}, ` : ''} {vehicleModel !== '' ? `${vehicleModel} ` : ''}
          </Typography>
          <Typography className="pt1" variant="body2">
            {vehicleYear}
          </Typography>
        </Grid>
      </Grid>
      <Box className={classes.bottomHighlight}>
        <LinkPrimary onClick={handleShowVehicleLookup}>Not the right vehicle?</LinkPrimary>
      </Box>
    </Box>
  );
};

export default VehicleLookUp;
