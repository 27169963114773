import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    border: `1px solid ${theme.palette.dark[300]}`,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.common.white,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.common.white,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      backgroundImage: `radial-gradient(${theme.palette.common.black} , ${theme.palette.common.black} 50%,transparent 0%)`,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.common.white,
    },
  },
  body: { ...theme.bodyStyles },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    height: '4.8rem',
    borderBottom: `3px solid ${theme.palette.green.main}`,
    '@media (max-width: 480px)': {
      height: '100%',
      flexDirection: 'column',
    },
  },
  radioTabContainer: {
    minWidth: '182px',
    padding: '12px 24px 12px 8px',
    marginRight: '10px',
    cursor: 'pointer',
    '&:first-child': {
      marginLeft: '1rem',
      '@media (max-width: 480px)': {
        borderBottom: `2px solid ${theme.palette.dark[300]}`,
        marginBottom: '0.25rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    },
    '&:last-child': {
      marginRight: '1rem',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
      '@media (max-width: 480px)': {
        marginTop: '0.25rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    },
  },
  radioTabActive: {
    backgroundColor: theme.palette.green.main,
  },
  radioTabInActive: {
    color: theme.palette.dark[300],
    height: '4.35rem',
    display: 'flex',
    marginTop: '0.25rem',
    borderTop: `2px solid ${theme.palette.dark[300]}`,
    borderLeft: `2px solid ${theme.palette.dark[300]}`,
    borderRight: `2px solid ${theme.palette.dark[300]}`,
    '&:hover': {
      color: theme.palette.common.black,
      borderTop: `2px solid ${theme.palette.common.black}`,
      borderLeft: `2px solid ${theme.palette.common.black}`,
      borderRight: `2px solid ${theme.palette.common.black}`,
    },
    zIndex: 0,
  },
  radioLabelContainer: {
    paddingLeft: '8px',
  },
  radioHeading: {
    marginTop: 0,
    marginBottom: 0,
  },
  radioSubHeading: {
    marginTop: 0,
    marginBottom: 0,
  },
  tabPanelContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  focus: {
    '& .focused-element': {
      boxShadow: `${theme.palette.common.black} 0 0 0 2px`,
      border: `2px solid ${theme.palette.dark[300]}`,
    },
  },
  formControlLabel: {
    margin: 0,
  },
}));
