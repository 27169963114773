import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      ...theme.typography.button,
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      color: theme.palette.dark[700],
      textTransform: 'none',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
        textDecorationThickness: '2px',
        textDecorationColor: theme.palette.dark[700],
      },
      '&:focus': {
        textDecoration: 'underline',
        textDecorationThickness: '2px',
        backgroundColor: theme.palette.utility.linkFocusBg,
        textDecorationColor: theme.palette.dark[700],
      },
    },
    disabled: {
      ...theme.typography.button,
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '20px',
      color: theme.palette.utility.linkDisabledText,
      textAlign: 'center',
      textTransform: 'none',
      textDecoration: 'underline',
      textDecorationThickness: '2px',
      textUnderlinePosition: 'under',
      textDecorationColor: theme.palette.utility.linkDisabledUnderline,
      paddingBottom: '2px',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationThickness: '2px',
        textUnderlinePosition: 'under',
        textDecorationColor: theme.palette.utility.linkDisabledUnderline,
      },
    },
    iconBack: {
      marginRight: '0.25rem',
    },
    iconForward: {
      marginLeft: '0.25rem',
    },
  }),
  { index: 1 },
);
