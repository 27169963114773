import React from 'react';
import { Link, LinkProps } from '@material-ui/core';
import { useStyles } from './LinkNavigationStyles';
import clsx from 'clsx';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

interface ILinkNavigation extends LinkProps {
  disabled?: boolean;
  arrowDirection?: 'back' | 'forward';
}

export const LinkNavigation: React.FC<ILinkNavigation> = ({
  href,
  onClick,
  children,
  className,
  disabled,
  arrowDirection,
}: ILinkNavigation) => {
  const classes = useStyles();

  if (disabled) {
    return (
      <Link className={classes.disabled}>
        {arrowDirection === 'back' ? (
          <ArrowBackIcon className={classes.iconBack} />
        ) : (
          <ArrowForwardIcon className={classes.iconForward} />
        )}{' '}
        {children}
      </Link>
    );
  } else {
    return (
      <Link component="button" className={clsx(classes.root, className)} href={href} onClick={onClick}>
        {arrowDirection === 'back' ? (
          <ArrowBackIcon className={classes.iconBack} />
        ) : (
          <ArrowForwardIcon className={classes.iconForward} />
        )}{' '}
        {children}
      </Link>
    );
  }
};

LinkNavigation.defaultProps = {
  disabled: false,
  underline: 'none',
  arrowDirection: 'back',
};

export default LinkNavigation;
