// @ts-nocheck
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import theme from '../../../theme';

export const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    backgroundColor: theme.palette.light[100],
    flexDirection: 'unset',
  },
  value: {
    backgroundColor: theme.palette.light[50],
  },
}));

export const datePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.palette.green.main,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '2px',
        minWidth: '18rem',
        '& $notchedOutline': {
          border: `2px solid ${theme.palette.dark[300]}`,
        },
        '&:hover $notchedOutline': {
          borderColor: theme.palette.common.black,
        },
        '&$focused $notchedOutline': {
          border: `2px solid ${theme.palette.light[100]}`,
        },
        '&$error $notchedOutline': {
          borderColor: theme.palette.utility.redLight,
          boxShadow: `inset 0 0 0 2px ${theme.palette.utility.redDark}`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.utility.redLight}`,
          },
        },
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: theme.palette.common.white,
      },
      toolbarBtnSelected: {
        color: theme.palette.common.white,
        fontSize: '1.5rem',
        fontWeight: 700,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: theme.palette.green.main,
      },
      dayDisabled: {
        color: theme.palette.dark[50],
      },
      current: {
        color: theme.palette.common.black,
      },
    },
  },
  palette: {
    primary: {
      main: theme.palette.common.black,
    },
  },
  shape: {
    borderRadius: 0,
  },
});
