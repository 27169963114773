export const comparisonWebsiteSources = ['ct', 'ms', 'rc', 'go', 'mo', 'us', 'direct'];

export const isValidComparisonWebsiteSource = (comparisonWebsiteSources: Array<string>, src: string): boolean => {
  return !comparisonWebsiteSources.includes(src.toLowerCase()) && process.env.NODE_ENV !== 'development' ? false : true;
};

const MILLISECONDS_IN_AN_HOUR = 60 * 60 * 1000;

const round = (num: number): number => Math.round(num * 100) / 100;
const calculateTotalCost = (vehicleCost: number, additionalOptionsCost: number): number =>
  round(vehicleCost + additionalOptionsCost) / 100;

const getBaseOptionDetails = (optionsDB, optionSelected) => {
  const baseOptionModel = optionsDB.filter((p) => p.option_id == optionSelected)[0];

  return baseOptionModel;
};

export const getCoverPricingData = (
  options,
  productName: string,
  additionalOption,
  vehicleAge: number,
  startDateIn,
  endDateIn,
  logging = false,
): { coverPrice: number } => {
  
  const startDate = new Date(new Date(startDateIn).getTime() + 12 * MILLISECONDS_IN_AN_HOUR);
  const endDate = new Date(new Date(endDateIn).getTime() + 12 * MILLISECONDS_IN_AN_HOUR);

  logging && console.log("product name:", productName);
  const productNameArray = productName.toLowerCase();
  logging && console.log("product name array:", productNameArray);

  logging && console.log("Looking for: ", productNameArray.toLowerCase());

  const baseOptionModel = getBaseOptionDetails(options, productName);
  logging && console.log("baseOptionModel ", baseOptionModel);

  let basePricePence = 0;

  logging && console.log("vehicleAge? ", vehicleAge);
  logging && console.log("price_by_vehicle_age? ", baseOptionModel.price_by_vehicle_age);

  const basePricingForAge = baseOptionModel.price_by_vehicle_age.find((model) => {
    return model.vehicle_age_band_min_years <= vehicleAge && model.vehicle_age_band_max_years >= vehicleAge;
  });

  if (baseOptionModel.option_type == 'ModelFixedDuration') {
    basePricePence = basePricingForAge.price_pence;
  } else if (baseOptionModel.option_type == 'ModelVariableDuration') {
    //In current system, trip duration is inclusive - ie the price is based on one day more than the differencee
    //between the start and end dates
    // the plus one at the end of the tripDuration is for trips of only one day (start and end date being the same)
    const tripDurationDays = Math.round((endDate.getTime() - startDate.getTime()) / (24 * MILLISECONDS_IN_AN_HOUR))+ 1;

    logging && console.log("Tripdurationdays ", tripDurationDays);
    logging && console.log("Tripdurationdays start date", startDate);
    logging && console.log("Tripdurationdays end date", endDate);

    const basePricingForDuration = basePricingForAge.price_per_trip_duration.find((model) => {
      return model.trip_duration_band_min_days <= tripDurationDays && model.trip_duration_band_max_days >= tripDurationDays;
    });
    basePricePence = basePricingForDuration.price_pence;
  }

  logging && console.log("basePricingForAge", basePricingForAge);

  let additionalPricePence = 0;

  if (additionalOption != undefined) {
    logging && console.log("additional option is:", additionalOption);

    additionalPricePence =
      additionalOption
        .map(option => {
          return options.find(model => model.option_id == option && model.option_type == 'Addon').price_pence;
        })
        .reduce((arr, curr) => arr + curr, 0);
  }
  logging && console.log('calculating price - base price pence: ', basePricePence);
  logging && console.log('calculating price - additional price pence: ', additionalPricePence);
  logging && console.log('calculated price: ', basePricePence + additionalPricePence);

  return {
    coverPrice: calculateTotalCost(basePricePence, additionalPricePence)
  };
};

export const calculateEndDateForAnnual = (
  // eslint-disable-next-line
  startDateIn,
  logging = false,
): Date => {
  const newStartDate = new Date(new Date(startDateIn).getTime() + 12 * MILLISECONDS_IN_AN_HOUR);
  logging && console.log('Deriving Annual end date from start date: ', startDateIn);
  logging && console.log('Deriving Annual end date from newStartDate date: ', newStartDate);

  const year = newStartDate.getFullYear();
  //get month is zero-based. Parsing a date from a string expects a 1-based month
  const month = newStartDate.getMonth();
  const day = newStartDate.getDate();

  // Stricter parsing for safari (chromium based browsers)
  const plusOneYear = new Date(year + 1, month, day, 12, 0, 0);

  const endDate = new Date(plusOneYear.getTime() - 24 * MILLISECONDS_IN_AN_HOUR);
  logging && console.log('Derived Annual end date : ', endDate);

  return endDate;
};
