import React from 'react';
import { Typography } from '../../components/atoms';

export const CoverDetailsSchema = {
  TripType: [
    {
      label: 'A single trip',
      value: 'Continental',
    },
    {
      label: 'Multiple trips',
      value: 'Annual',
    },
  ],
  CaravanTowCheck: [
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ],
  AccordionBody: {
    heading: 'View the list of countries we cover.',
    expandedHeading: 'Hide the list.',
    body: (
      <>
        <Typography>
          Andorra, Austria, Balearics, Belgium, Bulgaria, Canary Islands, Channel Isles, Corsica, Croatia, Cyprus,
          Czech Republic, Denmark, Estonia, Finland, France, Germany, Gibraltar, Greece, Hungary, Italy, Latvia,
          Liechtenstein, Lithuania, Luxembourg, Malta, Monaco, the Netherlands, Norway, Poland, Portugal, Romania, San Marino, Sardinia,
          Sicily, Slovakia, Slovenia, Spain, Sweden, Switzerland, Turkey in Europe and Üsküdar, and the Republic of Ireland.
        </Typography>
      </>
    ),
  },
};

export default CoverDetailsSchema;
