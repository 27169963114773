import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useStyles } from './ButtonSecondaryDarkStyles';
import clsx from 'clsx';

interface IButtonSecondaryDark extends ButtonProps {
  loading?: boolean;
  rootBtnClass: string;
  loadingBtnClass: string;
  loadingAnimationClass: string;
}

export const ButtonSecondaryDark: React.FC<IButtonSecondaryDark> = ({
  rootBtnClass,
  loadingBtnClass,
  loadingAnimationClass,
  disabled,
  className,
  loading,
  children,
  ...rest
}: IButtonSecondaryDark): JSX.Element => {
  const classes = useStyles();
  if (loading) {
    return (
      <Button className={clsx(rootBtnClass, className, loadingBtnClass)} {...rest} disabled>
        <div className={loadingAnimationClass}></div>
      </Button>
    );
  } else {
    return (
      <Button className={clsx(rootBtnClass, classes.btnSecondaryDark, className)} disabled={disabled} {...rest}>
        {children}
      </Button>
    );
  }
};

export default ButtonSecondaryDark;
