import React, { Dispatch, SetStateAction } from 'react';
import { useStyles } from '../PostcodeLookupStyles';
import { Typography, ButtonPrimary, ButtonSecondary } from '../../../atoms';
import { List } from '@material-ui/core';

interface IPostcodeLookupNoMatchesFoundModalBody {
  setShowNoMatchesModal: Dispatch<SetStateAction<boolean>>;
  postcode: string;
}

export const PostcodeLookupNoMatchesFoundModalBody: React.FC<IPostcodeLookupNoMatchesFoundModalBody> = ({
  setShowNoMatchesModal,
  postcode,
}: IPostcodeLookupNoMatchesFoundModalBody): JSX.Element => {
  const handleCancel = (): void => setShowNoMatchesModal(false);
  const classes = useStyles();

  return (
    <div className={classes.resultsContainer}>
      <Typography className={classes.information}>Searching for {postcode}</Typography>
      <Typography className={classes.information}>No match found.</Typography>
      <List component="nav" aria-label="empty list" className={classes.list}></List>
      <div className={classes.actionButtonsContainer}>
        <ButtonPrimary disabled={true}>Select</ButtonPrimary>
        <ButtonSecondary onClick={handleCancel}>Cancel</ButtonSecondary>
      </div>
    </div>
  );
};

export default PostcodeLookupNoMatchesFoundModalBody;
