import React from 'react';
import { useStyles } from './NavBarStyles';
import { AppBar, Grid, Container } from '@material-ui/core';
import { NavStepper, StateEditor, StateTracker } from '../../atoms';
import { StepContext } from '../../../contexts/StepContext';
import greenFlagLogo from '../../../assets/img/logo/GreenFlagLogoNoOutlineDesktop.svg';

export const NavBar: React.FC = (): JSX.Element => {
  const context = React.useContext(StepContext);
  const { showStepper } = context;
  const classes = useStyles();

  return (
    <>
      <AppBar className={classes.appBar} position="static">
        <Container className={classes.container}>
          <Grid container>
            <Grid item className={classes.greenFlagLogoGridContainer} xs={12} lg={12}>
              <img src={greenFlagLogo} alt="Green Flag Logo" />
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <Grid container>
        <Grid item xs={12}>
          {!showStepper ? <></> : <NavStepper />}
          {process.env.NODE_ENV === 'development' ? (
            <>
              <StateTracker />
              <StateEditor />
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default NavBar;
