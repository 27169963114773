import React from 'react';
import { Link, LinkProps } from '@material-ui/core';
import { useStyles } from './LinkSecondaryStyles';
import clsx from 'clsx';

interface ILinkSecondary extends LinkProps {
  disabled?: boolean;
}

export const LinkSecondary: React.FC<ILinkSecondary> = ({
  href,
  onClick,
  children,
  className,
  underline,
  disabled,
}: ILinkSecondary) => {
  const classes = useStyles();

  if (disabled) {
    return <Link className={classes.disabled}>{children}</Link>;
  } else {
    return (
      <Link
        component="button"
        className={clsx(classes.root, className, underline !== 'none' ? classes.underline : '')}
        href={href}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }
};

LinkSecondary.defaultProps = {
  disabled: false,
};

export default LinkSecondary;