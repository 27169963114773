import React from 'react';
import { Modal as MaterialUIModal, IconButton } from '@material-ui/core';
import { useStyles } from './ModalStyles';
import CloseIcon from '@material-ui/icons/Close';
import { ButtonPrimary, ButtonSecondary, Typography } from '../../atoms';
import ModalIcon from './ModalIcon.component';
import clsx from 'clsx';

export interface IModal {
  open: boolean;
  setOpen: any;
  onClose?: () => void;
  heading?: string;
  headingClassName?: string;
  modalContainerClassName?: string;
  body: JSX.Element | string;
  body2?: JSX.Element | string;
  body3?: JSX.Element | string;
  componentType?: 'button' | 'icon' | 'fragment';
  iconType?: 'info' | 'question';
  buttonLabel?: string;
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'generic';
  buttonDisabled?: boolean;
  buttonClassName?: string;
  disableBackdropClick?: boolean;
  hasCallUsLink: boolean;
  hasFooter: boolean;
  buttonUrl?: string;
}

interface IMuiModal {
  open: boolean;
  onClose: (() => void) | undefined;
  handleDefaultClose: (() => void) | undefined;
  disableBackdropClick: boolean | undefined;
}

const Modal: React.FC<IModal> = ({
  open,
  setOpen,
  onClose,
  heading,
  headingClassName,
  modalContainerClassName,
  body,
  body2,
  body3,
  componentType,
  buttonLabel,
  buttonType,
  buttonDisabled,
  buttonClassName,
  disableBackdropClick,
  iconType,
  hasCallUsLink,
  hasFooter,
  buttonUrl,
}) => {
  const classes = useStyles();

  const handleOpen = () => setOpen(true);
  const handleDefaultClose = () => setOpen(false);

  const getButton = (
    buttonDisabled: boolean,
    buttonType: 'primary' | 'secondary' | 'tertiary' | 'generic',
    buttonLabel: string,
    buttonClassName: string,
    handleOpen: () => void,
  ) => {
    switch (buttonType) {
      case 'primary':
        return (
          <ButtonPrimary disabled={buttonDisabled} className={buttonClassName} onClick={handleOpen}>
            {buttonLabel}
          </ButtonPrimary>
        );
      case 'secondary':
        return (
          <ButtonSecondary disabled={buttonDisabled} className={buttonClassName} onClick={handleOpen}>
            {buttonLabel}
          </ButtonSecondary>
        );
    }
  };

  const content = (
    <>
      <div className={clsx(modalContainerClassName, classes.paper)}>
        <div className={classes.headingContainer}>
          <Typography variant="h2" className={headingClassName ? headingClassName : classes.headingText}>
            {heading}
          </Typography>
          <IconButton
            className={classes.iconButton}
            aria-label="Close"
            onClick={onClose !== undefined ? onClose : handleDefaultClose}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        {hasCallUsLink === true && (
          <>
            <Typography className={clsx(classes.bodyText, 'pb2')}>
              Call us on{' '}
              <a className={classes.callUsLink} href="telephone:0800 400 638" rel="noreferrer">
                0800 400 638
              </a>
            </Typography>
          </>
        )}
        <Typography className={classes.bodyText}>{body}</Typography>
        <Typography className={classes.bodyText}>{body2}</Typography>
        <Typography className={clsx(classes.bodyText, 'pb3')}>{body3}</Typography>
      </div>
      {hasFooter === true && (
        <>
          <div className={classes.footerStyling}>
            {buttonUrl ? (
              // ToDo: Disable button until URL is retrieved.
              <ButtonPrimary href={buttonUrl}>let&apos;s go</ButtonPrimary>
            ) : (
              <ButtonPrimary onClick={onClose !== undefined ? onClose : handleDefaultClose}>OK, got it</ButtonPrimary>
            )}
          </div>
        </>
      )}
    </>
  );

  const MuiModal = ({ open, onClose, handleDefaultClose }: IMuiModal) => {
    const classes = useStyles();
    return (
      <div className={modalContainerClassName}>
        <MaterialUIModal
          open={open}
          onClose={onClose !== undefined ? onClose : handleDefaultClose}
          className={classes.modal}
          disableBackdropClick={disableBackdropClick}
        >
          <div>{content}</div>
        </MaterialUIModal>
      </div>
    );
  };

  if (
    componentType === 'button' &&
    buttonDisabled !== undefined &&
    buttonType !== undefined &&
    buttonLabel !== undefined &&
    buttonClassName !== undefined
  ) {
    const button = getButton(buttonDisabled, buttonType, buttonLabel, buttonClassName, handleOpen);

    return (
      <div className={modalContainerClassName}>
        {button}
        <MuiModal
          open={open}
          onClose={onClose}
          handleDefaultClose={handleDefaultClose}
          disableBackdropClick={disableBackdropClick}
        />
      </div>
    );
  } else if (componentType === 'icon' && iconType !== undefined) {
    return (
      <div className={modalContainerClassName}>
        <ModalIcon handleOpen={handleOpen} iconType={iconType} />
        <MuiModal
          open={open}
          onClose={onClose}
          handleDefaultClose={handleDefaultClose}
          disableBackdropClick={disableBackdropClick}
        />
      </div>
    );
  } else {
    return (
      <div className={modalContainerClassName}>
        <MuiModal
          open={open}
          onClose={onClose}
          handleDefaultClose={handleDefaultClose}
          disableBackdropClick={disableBackdropClick}
        />
      </div>
    );
  }
};

Modal.defaultProps = {
  buttonType: 'primary',
  buttonLabel: '',
  buttonDisabled: false,
  buttonClassName: '',
  componentType: 'icon',
  disableBackdropClick: false,
  iconType: 'info',
};

export default Modal;
