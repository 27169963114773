import React from 'react';
import { useStyles, HtmlTooltip } from './TooltipStyles';
import { TooltipProps } from '@material-ui/core';

export const Tooltip: React.FC<TooltipProps> = ({ title, children, ...rest }: TooltipProps): JSX.Element => {
  const classes = useStyles();
  return (
    <HtmlTooltip
      enterTouchDelay={0}
      classes={{ arrow: classes.arrow }}
      {...rest}
      title={
        <React.Fragment>
          <div className={classes.tooltipGreenLine}></div>
          <div className={classes.tooltipContent}>{title}</div>
        </React.Fragment>
      }
      PopperProps={{
        modifiers: {
          flip: { enabled: false },
        },
      }}
    >
      {children}
    </HtmlTooltip>
  );
};

export default Tooltip;
