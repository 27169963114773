import React, { useState } from 'react';

import { useStyles } from './AnchorAccordionStyles';
import { CSSAccordion, AccordionSummary, AccordionDetails } from './AnchorAccordionStyles';
import { Typography } from '../../atoms';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface IAccordion {
  heading: string;
  body: JSX.Element;
  defaultExpanded?: boolean;
  expandedHeading: string;
}

const AnchorAccordion: React.FC<IAccordion> = ({ heading, body, defaultExpanded, expandedHeading }: IAccordion): JSX.Element => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded || false);

  const handleAccordionExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <CSSAccordion
        expanded={expanded}
        onChange={handleAccordionExpansion}
        defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />} className={classes.accordionSummary} aria-controls="accordion1d-content" id="accordion1d-header">
          <Typography className={classes.accordionHeading}>{expanded ? expandedHeading : heading}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionBody}>
          {body}
        </AccordionDetails>
      </CSSAccordion>
    </div>
  );
};

export default AnchorAccordion;