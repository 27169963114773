import React, { useState } from 'react';
import { Grid, Container } from '@material-ui/core';
import { useStyles } from './FooterStyles';
import { Typography } from '../../atoms';
import greenFlagLogo from '../../../assets/img/logo/GreenFlagLogoNoOutlineDesktop.svg';
import { Modal } from '../../molecules/Modal';
import ContentSchema from './footerSchema.json';

declare const OneTrust: any;

const FooterLinksBar = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyles();

  const { modalData } = ContentSchema;

  return (
    <div className={classes.linksBarBg}>
      <Container className={classes.linksBarContainer}>
        <div className={classes.linksBar}>
          <ul>
            <li>
              <a
                href="https://www.greenflag.com/cookies-notice?src=pcw"
                target="_blank"
                rel="noreferrer"
                className={classes.textHover}
              >
                Cookies
              </a>
            </li>
            <li>
              <a
                href="#top"
                onClick={() => OneTrust.ToggleInfoDisplay()}
                id="ot-sdk-btn"
                target="_self"
                rel="noreferrer"
                className={classes.textHover}
              >
                Manage Cookies
              </a>
            </li>
            <li>
              <a
                href="https://u-k-insurance.co.uk/brands-policy.html"
                target="_blank"
                rel="noreferrer"
                className={classes.textHover}
              >
                Privacy
              </a>
            </li>
            <li>
              <a href="#" onClick={() => setModalOpen(true)} className={classes.textHover}>
                Call us
              </a>
            </li>
            {modalOpen === true && (
              <li>
                <Modal
                  open={modalOpen}
                  hasCallUsLink={true}
                  hasFooter={true}
                  setOpen={setModalOpen}
                  heading={modalData.heading}
                  body={modalData.body}
                  body2={modalData.body2}
                  body3={modalData.body3}
                />
              </li>
            )}
          </ul>
        </div>
      </Container>
    </div>
  );
};

const FooterText = (): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.footerTextContainer}>
      <Typography variant="body2">
        Green Flag Breakdown Cover is underwritten by U K Insurance Limited who are authorised by the Prudential
        Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority.
        Registration number 202810. The Financial Services Register can be accessed through{' '}
        <a href="https://www.fca.org.uk" target="_blank" rel="noreferrer">
          www.fca.org.uk
        </a>
        . Registered office: The Wharf, Neville Street, Leeds LS1 4AZ. Registered in England and Wales No.1179980.
      </Typography>
    </div>
  );
};

const FooterCopyrightText = (): JSX.Element => {
  const classes = useStyles();
  return (
    <Typography className={classes.copyrightText} variant="body2">
      &copy; Green Flag {new Date().getFullYear()}
    </Typography>
  );
};

export const Footer: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <FooterLinksBar />
      <Container className={classes.container}>
        <Grid className={classes.gridContainer} container direction="row">
          <Grid className={classes.greenFlagLogoContainer} item xs={12} md={2}>
            <img src={greenFlagLogo} alt="Green Flag Logo" />
          </Grid>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={9}>
            <FooterText />
          </Grid>
          <Grid item xs={12} md={2} sm={12}></Grid>
          <Grid item xs={12} md={1} sm={12}></Grid>
          <Grid item xs={12} md={9} sm={12}>
            <FooterCopyrightText />
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
