import React from 'react';
import { TextField as MuiTextField, TextFieldProps } from '@material-ui/core';
import { useStyles } from './TextFieldStyles';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
interface ITextField {
  name: string;
  control: any;
  inputPropsClassName?: string;
  stripeInputProps?: any;
  endAdornment?: JSX.Element;
}

interface IUncontrolledTextField {
  stripeInputProps?: any;
  forComponent?: 'vehicle-lookup';
}

type ITextFieldProps = ITextField & TextFieldProps;
type IUncontrolledTextFieldProps = IUncontrolledTextField & TextFieldProps;

export const TextField: React.FC<ITextFieldProps> = ({
  id,
  style,
  type,
  placeholder,
  name,
  control,
  className,
  inputProps,
  disabled,
  defaultValue,
  autoComplete,
  inputPropsClassName,
  fullWidth,
  onKeyPress,
  onKeyDown,
  onFocus,
  error,
  InputProps,
  endAdornment,
  stripeInputProps,
  ...rest
}: ITextFieldProps) => {
  const classes = useStyles();
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field }) => (
        <MuiTextField
          {...field}
          id={id}
          style={style}
          type={type}
          className={clsx(className, classes, classes.root, disabled || error ? '' : classes.textFieldEnabled)}
          inputProps={{
            ...InputProps,
            ...inputProps,
            className: [classes.root, inputPropsClassName],
          }}
          InputProps={{
            ...InputProps,
            endAdornment,
          }}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          fullWidth={fullWidth}
          onChange={field.onChange}
          inputRef={field.ref}
          ref={undefined}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          error={error}
          variant="outlined"
          {...stripeInputProps}
          {...rest}
        />
      )}
    />
  );
};

export const UncontrolledTextField: React.FC<IUncontrolledTextFieldProps> = ({
  id,
  style,
  type,
  placeholder,
  className,
  inputProps,
  stripeInputProps,
  onChange,
  disabled,
  value,
  onPaste,
  error,
  InputProps,
}: IUncontrolledTextFieldProps) => {
  const classes = useStyles();

  return (
    <MuiTextField
      onChange={onChange}
      id={id}
      style={style}
      type={type}
      className={className}
      placeholder={placeholder}
      InputProps={{...InputProps}}
      inputProps={{
        ...inputProps,
        className: [classes.root],
      }}
      disabled={disabled}
      value={value}
      onPaste={onPaste}
      error={error}
      {...stripeInputProps}
    />
  );
};

export default TextField;
