import React, { Dispatch, SetStateAction, useState } from 'react';
import { useStyles } from './VehicleInformationStyles';
import { Typography, ButtonPrimary, TextField, SelectDropdown, DatePicker } from '../../../atoms';
import { Grid } from '@material-ui/core';
import { FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import vehicleInformationData from './VehicleInformationData.json';
import clsx from 'clsx';
import { subYears } from 'date-fns';

const initialVehicleFormValues = {
  make: '',
  model: '',
  year: null,
};

interface IVehicleInformationData {
  make: string;
  model: string;
  year: string;
}

// 17 since the requirements are that the vehicle must be 16 years old or less (including 16 years old)
const minVehicleYear: Date = subYears(new Date(), 17);

const schema = yup.object().shape({
  make: yup.string().required("Let us know this vehicle's manufacturer (for example, Ford, Vauxhall, or Nissan)"),
  model: yup
    .string()
    .required("Let us know this vehicle's model")
    .matches(/^[a-zA-Z0-9\\s]+$/, 'Please enter a valid model'),
  year: yup
    .date()
    .required('Please select a year')
    .nullable()
    .min(minVehicleYear, "Sorry, but we're not able to cover vehicles that are over 16 years old")
    .max(new Date(), 'The year of manufacture must be the current year or lower')
    .typeError('Please enter a valid date'),
});

const { dropdownData } = vehicleInformationData;

interface IVehicleInformation {
  entry: any;
  saveFields: (data?: any) => void;
  informationIsCorrect: boolean | undefined;
  setInformationIsCorrect: Dispatch<SetStateAction<boolean | undefined>>;
}

interface IManualForm {
  control: any;
  errors: FieldErrors;
  handleSubmit: any;
  saveFields: (data?: any) => void;
  setInformationIsCorrect: Dispatch<SetStateAction<boolean | undefined>>;
  formState: any;
}

const ManualForm = ({
  control,
  errors,
  handleSubmit,
  saveFields,
  setInformationIsCorrect,
}: IManualForm): JSX.Element => {
  const classes = useStyles();

  const onSubmit = (data: IVehicleInformationData) => {
    saveFields({
      ...data,
      year: new Date(data.year).getFullYear(),
      model: data.model,
      make: data.make,
    });
    setInformationIsCorrect(true);
  };

  return (
    <div className={classes.vehicleInfoForm}>
      <Grid container>
        <Grid item className="pt2 pb1" xs={12} lg={12}>
          <Typography variant="h3">What&apos;s the vehicle make?</Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <SelectDropdown
            name="make"
            id="make"
            className={classes.inputFields}
            control={control}
            variant="outlined"
            defaultValue=""
            data={dropdownData}
          />
          {errors.make && (
            <Typography className={clsx(classes.maxWidth20, 'fieldErrorLong pt1')}>{errors.make.message}</Typography>
          )}
        </Grid>

        <Grid item className="pt2" xs={12} lg={12}>
          <Typography variant="h3">What&apos;s the model?</Typography>
        </Grid>
        <Grid item xs={12} lg={12} className="pt1">
          <TextField className={classes.inputFields} placeholder="e.g. A1" id="model" name="model" control={control} />
          {errors.model && <Typography className="fieldError pt1">{errors.model.message}</Typography>}
        </Grid>

        <Grid item className="pt2" xs={12} lg={12}>
          <Typography variant="h3">What&apos;s the year of manufacture?</Typography>
        </Grid>
        <Grid item className="py1" xs={12} lg={12}>
          <DatePicker
            name="year"
            id="year"
            views={['year']}
            placeholder="e.g. 2015"
            format="yyyy"
            disableFuture={true}
            control={control}
          />
          {errors.year && (
            <Typography className={clsx(classes.maxWidth20, 'fieldErrorLong pt1')}>{errors.year.message}</Typography>
          )}
        </Grid>
      </Grid>
      <Grid container className="pt2">
        <Grid item xs={12} lg={12}>
          <ButtonPrimary onClick={handleSubmit(onSubmit)} className={classes.vehicleDetailsSubmitBtn}>
            Select this vehicle
          </ButtonPrimary>
        </Grid>
      </Grid>
    </div>
  );
};

export const VehicleInformation: React.FC<IVehicleInformation> = ({
  saveFields,
  informationIsCorrect,
  setInformationIsCorrect,
}: IVehicleInformation): JSX.Element => {
  const { handleSubmit, control, formState } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: initialVehicleFormValues,
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const { errors } = formState;

  return (
    <div>
      {informationIsCorrect === false ? (
        <ManualForm
          control={control}
          errors={errors}
          handleSubmit={handleSubmit}
          saveFields={saveFields}
          setInformationIsCorrect={setInformationIsCorrect}
          formState={formState}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default VehicleInformation;
