import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: 0,
    backgroundColor: theme.palette.dark[800],
  },
  stepperContainer: {
    display: 'flex',
    paddingLeft: 0,
    margin: 0,
    '@media (max-width: 374px)': {
      flexDirection: 'row',
    },
    '@media (max-width: 767px)': {
      paddingLeft: '1rem',
    },
  },
  stepper: {
    display: 'flex',
    maxWidth: '1280px',
    margin: '0 auto',
    padding: '1.5rem',
    backgroundColor: theme.palette.dark[800],
    color: theme.palette.dark[50],
    textTransform: 'uppercase',
    '& .active': {
      color: theme.palette.common.white,
    },
    '@media (max-width: 440px)': {
      padding: '1rem',
    },
  },
  stepContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1.5rem',
    '& h6': {
      marginLeft: '0.6rem',
      fontSize: '1.125rem',
    },
    '@media (max-width: 440px)': {
      '& h6': {
        fontSize: '0.9rem',
      },
    },
  },
  step: {
    marginTop: 0,
    listStyleType: 'none',
  },
  circleInActive: {
    display: 'flex',
    height: '1.45rem',
    width: '1.5rem',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    outlineOffset: '-3px',
    outline: '3px solid grey',
    fontWeight: 700,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  circleActive: {
    display: 'flex',
    height: '1.45rem',
    width: '1.5rem',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.green.main,
    color: theme.palette.common.black,
    outlineOffset: '-3px',
    outline: '3px solid transparent',
    '&:first-child': {
      marginLeft: 0,
    },
  },
  stepActive: {
    color: theme.palette.common.white,
  },
  stepSpanActive: {
    color: theme.palette.green.main,
    fontWeight: 700,
  },
  stepText: {
    '@media (max-width: 767px)': {
      display: 'none',
    },
    display: 'block',
  }
}));
