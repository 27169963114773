import React, { useEffect } from 'react';
import { StepContext } from '../../../contexts/StepContext';
import { useHistory } from 'react-router-dom';

export const StepChecker: React.FC = () => {
  const { activeStep } = React.useContext(StepContext);
  const history = useHistory();

  useEffect(() => {
    window.onbeforeunload = () => true;
    if (activeStep === 1) {
      history.push('/');
    }
    window.scrollTo(0, 0);
  }, [activeStep, history]);

  return <></>;
};

export default StepChecker;
