import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '777px',
      margin: 'auto',
    },
    paper: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(2, 4, 3),
      '@media (min-width: 767px)': {
        minWidth: '35rem',
      },
    },
    headingContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '0.75rem',
    },
    headingText: {
      flexDirection: 'row',
      padding: '2rem 2rem 2rem 0',
    },
    bodyText: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '25px',
      paddingTop: '1rem',
    },
    iconButton: {
      padding: 0,
      color: theme.palette.common.black,
    },
    closeIcon: {
      float: 'right',
      fontSize: '3rem',
      marginLeft: 'auto',
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.error.main,
      },
    },
    helpIcon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    footerStyling: {
      background: theme.palette.utility.modalFooter,
      padding: '2rem',
    },
    callUsLink: {
      color: theme.palette.green[600],
    },
  }),
);
