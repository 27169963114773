import React from 'react';
import InfoIcon from '../../../assets/img/icons/info.png';
import HelpIcon from '@material-ui/icons/Help';
import { useStyles } from './ModalIconStyles';

export interface IModalIcon {
  iconType: 'info' | 'question';
  handleOpen: () => void;
}

export const ModalIcon: React.FC<IModalIcon> = ({ iconType, handleOpen }: IModalIcon) => {
  const classes = useStyles();

  switch (iconType) {
    case 'info':
      return <img src={InfoIcon} className={classes.infoIcon} onClick={handleOpen} />;
    case 'question':
      return <HelpIcon className={classes.helpIcon} onClick={handleOpen} />;
  }
};

export default ModalIcon;
