import React from 'react';
import { useStyles } from './YourCoverReceiptStyles';
import { Box } from '@material-ui/core';
import { Typography } from '..';
import { format } from 'date-fns';
import { convertDateToDlgFormat } from '../../../utils/dateFormattingUtils';

interface IYourCoverReceipt {
  startDate: Date | string | null | undefined;
  endDate: Date | string | null | undefined;
}

export const YourCoverReceipt: React.FC<IYourCoverReceipt> = ({ startDate, endDate }: IYourCoverReceipt): JSX.Element => {
  const classes = useStyles();

  const formattedPolicyStartDate = startDate
    ? convertDateToDlgFormat(startDate.toString())
    : null;

    const formattedPolicyEndDate = endDate
    ? convertDateToDlgFormat(endDate.toString())
    : null;

  const policyStartDate = formattedPolicyStartDate ? format(new Date(formattedPolicyStartDate), 'do MMMM, yyyy') : '';
  
  const policyEndDate = formattedPolicyEndDate ? format(new Date(formattedPolicyEndDate), 'do MMMM, yyyy') : '';

  return (
    <>
      <Box className={classes.yourCoverReceiptContainer}>
        <Box className={classes.yourCoverReceiptInnerContainer}>

          <Box className={classes.policyDateContainer}>
            <Box className={classes.policyLabelContainer}>
              <Typography className={classes.policyLabel} variant="body2">
                Policy start date:
              </Typography>
            </Box>
            <Box>
              <Typography>
                <Box component="span">00:01am, {policyStartDate}</Box>
              </Typography>
            </Box>
          </Box>

          <Box className={classes.policyDateContainer}>
            <Box className={classes.policyLabelContainer}>
              <Typography className={classes.policyLabel} variant="body2">
                Policy end date:
              </Typography>
            </Box>
            <Box>
              <Typography>
                <Box component="span">23:59pm, {policyEndDate}</Box>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default YourCoverReceipt;
