import React from 'react';
import { useStyles } from './NextStepsStyles';
import { Grid, Container } from '@material-ui/core';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { Typography } from '../../atoms';

const NextSteps: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
        <Grid container>
            <Container className="pb2">
                <div className={classes.dFlex}>
                    <Grid item xs={1}>
                        <FindInPageIcon className={classes.icons} />
                    </Grid>
                    <Grid item xs={11} className={classes.responsiveIconPadding}>
                        <Typography variant="h3" className={classes.headings}>
                            Make sure you check over your policy documents.
                        </Typography>
                        <Typography className="pt1" variant="body2">
                            Your docs will be sent to you within the next 7-10 days.
                        </Typography>
                        <Typography variant="body2">
                            Once they&apos;ve arrived, make sure you read through them carefully to check everything&apos;s as expected.
                        </Typography>
                    </Grid>
                </div>
            </Container>
        </Grid>
    </div>
  );
};

export default NextSteps;
