import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    checkboxContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    ul: {
      paddingLeft: '1.5rem',
      '& li': {
        fontSize: '1rem',
      },
    },
    checkbox: {
      '& span': {
        '& svg': {
          width: '36px',
          height: '36px',
        },
      },
    },
    inputFields: {
      maxWidth: '24.5rem',
      width: '100%',
    },
    actionButton: {
      marginTop: '1rem',
      '& button': {
        minWidth: '13rem',
        '@media (min-width: 321px)': {
          minWidth: '14rem',
        },
      },
      '@media (max-width: 410px)': {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        '& button': {
          margin: '1rem 0',
        },
      },
    },
    responsivePadding: {
      marginTop: '1.5rem',
      '@media (min-width: 767px)': {
        marginTop: '3rem',
      },
    },
    dobField: {
      minWidth: '18rem',
    },
    maxWidth90: {
      maxWidth: '90%',
    },
  }),
  { index: 1 },
);
