import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    yourCoverContainer: {
      maxWidth: '44rem',
      marginBottom: '3rem',
      '& h3': {
        marginBottom: '2rem',
      },
    },
    yourCoverGridItem: {
      display: 'flex',
      marginBottom: '1.5rem',
      '&:nth-last-child(2)': {
        marginBottom: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
      '@media (max-width: 600px)': {
        '&:nth-last-child(2)': {
          marginBottom: '1.5rem',
        },
      },
    },
    checkIcon: {
      color: theme.palette.green[800],
      marginRight: '0.5rem',
    },
    infoIconButton: {
      marginLeft: 'auto',
      padding: 0,
      display: 'flex',
      alignSelf: 'flex-start',
    },
    infoIcon: {
      color: theme.palette.grey[600],
    },
    infoIconHighlight: {
      color: theme.palette.green[700],
    },
    secondGridColumn: {
      marginLeft: '3rem',
      '@media (max-width: 599px)': {
        marginLeft: 0,
      },
    },
    coverKeyPoint: {
      paddingRight: '1rem',
    },
    yourCoverBackground: {
      border: `2px solid ${theme.palette.light[400]}`,
      borderRadius: '4px',
      padding: '0 2.5rem 2.5rem 2.5rem',
      '@media (max-width: 765px)': {
        padding: '1.5rem',
      },
    },
  }),
  { index: 1 },
);
