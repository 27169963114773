import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    policyPaymentContainer: {
      maxWidth: '44rem',
      marginBottom: '3rem',
      '& h3': {
        marginBottom: '2rem',
      },
    },
    policyPaymentContainerBg: {
      border: `2px solid ${theme.palette.light[400]}`,
      borderRadius: '4px',
      padding: '1rem',
      '@media (min-width: 767px)': {
        padding: '2.5rem',
      },
    },
    policyPaymentHeading: {
      ...theme.leads.lead1,
      marginBottom: '1.5rem',
      lineHeight: '1.375rem',
    },
    valueContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      '@media (max-width: 510px)': {
        flexDirection: 'column',
      },
    },
    divider: {
      margin: '1.5rem 0',
    },
    values: theme.leads.lead1,
    noticeContainer: {
      '@media (max-width: 510px)': {
        '&:first-child': {
          marginBottom: '0.25rem',
        },
      },
    },
    notice: {
      lineHeight: '1.375rem',
    },
  }),
  { index: 1 },
);
