import React, { useContext } from 'react';
import { Container, Grid } from '@material-ui/core';
import { StepContext } from '../../contexts/StepContext';
import { DocumentTitle } from '../../components/atoms';
import { useHistory } from 'react-router-dom';
import { add, format } from 'date-fns';
import axios from 'axios';
import uuid from 'uuid';

export const FakeComparison: React.FC = () => {
  const {} = useContext(StepContext);

  const history = useHistory();
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const {
      Source,
      Title,
      FirstName,
      LastName,
      DateOfBirth,
      EmailAddress,
      PhoneNumber,
      Postcode,
      AddressLine1,
      AddressLine2,
      AddressLine3,
      VehicleType,
      RegistrationNumber,
      VehicleMake,
      VehicleModel,
      VehicleAge,
      ProductName,
      CoverPrice,
      PolicyStartDate,
      PolicyEndDate,
      quote_id,
    } = e.target.elements;

    // axios post

    axios
      .post(`${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_LANDING_ENDPOINT}/${Source.value}`, {
        //Source:Source.value,
        Title: Title.value,
        FirstName: FirstName.value,
        LastName: LastName.value,
        DateOfBirth: DateOfBirth.value,
        EmailAddress: EmailAddress.value,
        PhoneNumber: PhoneNumber.value,
        Postcode: Postcode.value,
        AddressLine1: AddressLine1.value,
        AddressLine2: AddressLine2.value,
        AddressLine3: AddressLine3.value,
        VehicleType: VehicleType.value,
        RegistrationNumber: RegistrationNumber.value,
        VehicleMake: VehicleMake.value,
        VehicleModel: VehicleModel.value,
        VehicleAge: VehicleAge.value,
        ProductName: ProductName.value,
        CoverPrice: CoverPrice.value,
        PolicyStartDate: PolicyStartDate.value,
        PolicyEndDate: PolicyEndDate.value,
        quote_id: quote_id.value,
        isFakeComparison: true,
      })
      .then(function (response) {
        console.log('IN then block');
        console.log(response);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status == 303) {
            const firstSlash = error.response.data.indexOf('/');
            const relativePath = error.response.data.slice(firstSlash);
            console.log(relativePath);
            history.push(relativePath);
          }
        } else {
        }
      });
  };

  // if (process.env.NODE_ENV !== "development") {
  //   history.push('/');
  //   return <></>;
  // };

  return (
    <Container>
      <DocumentTitle title="FakeComparisonSite" />
      <h1>Fake Comparison Site</h1>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <form method="post" id="test-data" onSubmit={onSubmit}>
            <div>
              <label>Source</label>
              <select name="Source">
                <option value="ct">Compare the Market</option>
                <option value="ms">Money Supermarket</option>
                <option value="rc">Confused.Com</option>
                <option value="go">Go Compare</option>
                <option value="mo">money.co.uk</option>
                <option value="us">USwitch</option>
              </select>
            </div>

            <div>
              <label>Title</label>
              <input type="text" name="Title" defaultValue="Mr" />
              <span>Mr, Mrs, Ms, Miss, Doctor, Reverend, Sir</span>
            </div>

            <div>
              <label>First name</label>
              <input type="text" name="FirstName" defaultValue="Joe" />
            </div>

            <div>
              <label>Last name</label>
              <input type="text" name="LastName" defaultValue="Bloggs" />
            </div>

            <div>
              <label>Date of birth</label>
              <input type="text" name="DateOfBirth" defaultValue="01/02/1980" />
            </div>

            <div>
              <label>Email address</label>
              <input type="email" name="EmailAddress" defaultValue="joe@example.com" />
            </div>

            <div>
              <label>Phone number</label>
              <input type="text" name="PhoneNumber" defaultValue="01234567890" />
            </div>

            <div>
              <label>Postcode</label>
              <input type="text" name="Postcode" defaultValue="SO123ME" />
            </div>

            <div>
              <label>Address line 1</label>
              <input type="text" name="AddressLine1" defaultValue="1" />
              <span>House</span>
            </div>

            <div>
              <label>Address line 2</label>
              <input type="text" name="AddressLine2" defaultValue="Some Place" />
              <span>Road</span>
            </div>

            <div>
              <label>Address line 3</label>
              <input type="text" name="AddressLine3" defaultValue="Somewhere" />
              <span>Town/City</span>
            </div>

            <div>
              <label>Vehicle type</label>
              <input type="text" name="VehicleType" defaultValue="Bike" />
              <span>Car, Van, Bike</span>
            </div>

            <div>
              <label>Registration number</label>
              <input type="text" name="RegistrationNumber" defaultValue="AC904JOE" />
            </div>

            <div>
              <label>Vehicle make</label>
              <input type="text" name="VehicleMake" defaultValue="Honda" />
            </div>

            <div>
              <label>Vehicle model</label>
              <input type="text" name="VehicleModel" defaultValue="C90" />
            </div>

            <div>
              <label>Vehicle age</label>
              <input type="number" name="VehicleAge" step="1" defaultValue="10" />
            </div>

            <div>
              <label>Product name</label>
              <input type="text" name="ProductName" defaultValue="Continental Europe" style={{ minWidth: '30rem' }} />
            </div>

            <div>
              <label>Cover price</label>
              <input type="text" name="CoverPrice" defaultValue="1234.56" />
            </div>

            <div>
              <label>Policy start date</label>
              <input
                type="text"
                name="PolicyStartDate"
                defaultValue={format(add(new Date(), { days: 1 }), 'yyyy/MM/dd')}
              />
            </div>

            <div>
              <label>Policy end date</label>
              <input
                type="text"
                name="PolicyEndDate"
                defaultValue={format(add(new Date(), { days: 3 }), 'yyyy/MM/dd')}
              />
            </div>

            <div>
              <label>Quote ID</label>
              <input type="text" name="quote_id" defaultValue={uuid.v4()} />
            </div>

            <div>
              <button className="submit">Submit data</button>
            </div>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FakeComparison;
