import React from 'react';

declare global {
  interface Window {
    Trustpilot: any;
  }
}

interface ITrustBox {
  className?: string;
  dataTemplateId?: string;
  dataBusinessUnitId?: string;
  locale?: string;
  theme?: string;
}

export const TrustBox: React.FC<ITrustBox> = ({
  className,
  dataTemplateId,
  dataBusinessUnitId,
  locale,
  theme,
}: ITrustBox): JSX.Element => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      className={className}
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      data-locale={locale}
      data-template-id={dataTemplateId}
      data-businessunit-id={dataBusinessUnitId}
      data-theme={theme}
    >
      <a href="https://www.trustpilot.com/review/www.greenflag.com" target="_blank" rel="noreferrer">
        {' '}
        Trustpilot
      </a>
    </div>
  );
};

TrustBox.defaultProps = {
  dataTemplateId: '53aa8807dec7e10d38f59f32',
  dataBusinessUnitId: '488b79c700006400050309a2',
  locale: 'en-GB',
  theme: 'light',
};

export default TrustBox;
