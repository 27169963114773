import React from 'react';
import { ButtonProps } from '@material-ui/core';
import { useStyles } from './ButtonPrimaryStyles';
import ButtonPrimaryLight from './ButtonPrimaryLight/ButtonPrimaryLight.component';
import ButtonPrimaryDark from './ButtonPrimaryDark/ButtonPrimaryDark.component';

interface IButtonPrimary extends ButtonProps {
  buttonType?: 'dark' | 'light';
  loading?: boolean;
}

export const ButtonPrimary: React.FC<IButtonPrimary> = ({
  buttonType,
  disabled,
  className,
  loading,
  children,
  ...rest
}: IButtonPrimary): JSX.Element => {
  const classes = useStyles();
  switch (buttonType) {
    case 'light':
      return (
        <ButtonPrimaryLight
          rootBtnClass={classes.rootBtnClass}
          loadingBtnClass={classes.loadingButton}
          loadingAnimationClass={classes.loadingAnimation}
          disabled={disabled}
          className={className}
          loading={loading}
          {...rest}
        >
          {children}
        </ButtonPrimaryLight>
      );
    case 'dark':
      return (
        <ButtonPrimaryDark
          rootBtnClass={classes.rootBtnClass}
          loadingBtnClass={classes.loadingButton}
          loadingAnimationClass={classes.loadingAnimation}
          disabled={disabled}
          className={className}
          loading={loading}
          {...rest}
        >
          {children}
        </ButtonPrimaryDark>
      );
    default:
      return (
        <ButtonPrimaryLight
          rootBtnClass={classes.rootBtnClass}
          loadingBtnClass={classes.loadingButton}
          loadingAnimationClass={classes.loadingAnimation}
          disabled={disabled}
          className={className}
          loading={loading}
          {...rest}
        >
          {children}
        </ButtonPrimaryLight>
      );
  }
};

export default ButtonPrimary;
