import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  btnSecondaryDark: {
    '&:focus': {
      backgroundColor: theme.palette.green[50],
      boxShadow: `0px 1px 4px 0px ${theme.palette.green.main}`,

      '& .MuiSvgIcon-root': {
        color: theme.palette.dark[900],
      },
    },
    '&.Mui-disabled': {
      border: `2px solid transparent`,
      backgroundColor: theme.palette.utility.btnDisabledDarkBg,
      '&.MuiButton-text': {
        color: theme.palette.dark[200],
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.dark[900],
      },
    },
  },
}));
