import { makeStyles, Theme, withStyles, Checkbox as MaterialUiCheckbox } from '@material-ui/core';

export const Checkbox = withStyles((theme) => ({
  root: {
    color: theme.palette.common.black,
    '&$checked': {
      color: theme.palette.green[800],
    },
  },
  checked: {},
}))(MaterialUiCheckbox);

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: theme.palette.light[100],
    marginLeft: 0,
    marginTop: '0.5rem',
    marginBottom: '0.6rem',
    padding: '1.25rem',
    border: `2px solid ${theme.palette.dark[400]}`,
    '&:active': {
      border: `2px solid ${theme.palette.dark[400]}`,
      backgroundColor: theme.palette.light[100],
    },
    '& span': {
      ...theme.bodyStyles.body2,
      lineHeight: '1.75rem',
      display: 'flex',
      flexDirection: 'column',
      padding: '0 0.15rem',
    },
    '& span:first-child': {
      ...theme.leads.lead1,
      lineHeight: '1.6rem',
      padding: '0 0.15rem',
    },
    '@media (max-width: 600px)': {
      '& span:first-child': {
        ...theme.leads.lead2,
        lineHeight: '1.8rem',
      },
      '& span': {
        ...theme.bodyStyles.body2,
      },
    },
  },
  selectedCheckbox: {
    border: `2px solid ${theme.palette.green[500]}`,
    backgroundColor: theme.palette.green[50],
  },
  unselectedCheckbox: {
    backgroundColor: theme.palette.light[100],
  },
  checkbox: {
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.palette.common.black,
    marginLeft: 0,
  },
  error: {
    color: theme.palette.utility.redDark,
  },
}), { index: 1 });
