import React from 'react';
import { Typography as MuiTypography, withStyles } from '@material-ui/core';
import { typographyStyles } from './TypographyStyles';
import clsx from 'clsx';

interface ITypography {
  variant?: any;
  classes?: any;
  className?: string;
  forwardedRef?: any;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  paragraph?: boolean;
  component?: any;
  gutterBottom?: boolean;
  noWrap?: boolean;
  props?: any;
  fontFamily?: 'Oswald' | 'Roboto';
}

export const Typography: React.FC<ITypography> = ({
  variant,
  classes,
  className,
  forwardedRef,
  align,
  paragraph,
  component,
  gutterBottom,
  noWrap,
  fontFamily,
  ...props
}: ITypography) => {
  const isCustomVariant = Object.keys(classes).indexOf(variant) > -1;
  const headingVariants = ['h0', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
  const isOswald = fontFamily === 'Oswald';

  return (
    <MuiTypography
      className={clsx(
        isCustomVariant
          ? clsx(isOswald ? classes.oswald : classes.roboto, classes[variant], className)
          : clsx(isOswald ? classes.oswald : classes.roboto, className),
        isOswald == false && headingVariants.includes(variant) ? clsx(classes.roboto500, className) : className,
      )}
      variant={variant === 'h0' ? 'h1' : isCustomVariant ? undefined : variant}
      ref={forwardedRef}
      align={align}
      paragraph={paragraph}
      component={component}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      {...props}
    />
  );
};

Typography.defaultProps = {
  fontFamily: 'Roboto',
};

export default withStyles(typographyStyles)(Typography);
