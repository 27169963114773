import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  vehicleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    '@media (max-width: 750px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  vehicleSearch: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      alignItems: 'start',
      '& div': {
        marginLeft: 0,
      },
      '& p': {
        marginBottom: '0.75rem',
      },
    },
    '@media (max-width: 750px)': {
      marginRight: 'auto',
    },
  },
  vehicleBtnOptions: {
    marginLeft: '1rem',
    '@media (max-width: 750px)': {
      marginTop: '0.75rem',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginLeft: 0,
    },
  },
  vehicleTextField: {
    marginLeft: '1rem',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  vehicleDeleteDesktop: {
    '@media (max-width: 750px)': {
      display: 'none',
    },
  },
  vehicleDeleteMobile: {
    '@media (min-width: 750px)': {
      display: 'none',
    },
    '& button': {
      marginTop: '0.75rem',
      marginLeft: 0,
    },
  },
  clearBtn: {
    marginLeft: 0,
  },
  registrationNumberLabel: {
    ...theme.form.labels.inputField.label,
    color: theme.palette.dark[800],
    marginBottom: '1rem',
  },
  vehicleLookUpContainer: {
    border: `2px solid ${theme.palette.dark[500]}`,
    borderRadius: '5px',
    maxWidth: '40rem',
    marginBottom: '3rem',
  },
  vehicleLookUpGrid: {
    padding: '1rem 1.75rem',
  },
  bottomHighlight: {
    padding: '0rem 1.25rem',
    backgroundColor: theme.palette.light[100],
  },
  lookupContainer: {
    display: 'flex',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
    },
  },
  lookupBtn: {
    marginLeft: '2rem',
    maxWidth: '11rem',
    '@media (max-width: 500px)': {
      marginTop: '1rem',
      marginLeft: '0rem',
    },
  },
  responsivePadding: {
    '@media (max-width: 425px)': {
      paddingTop: '1rem',
    },
  },
  regBoxContainer: {
    display: 'flex',
    paddingRight: '1rem',
  },
  regBox: {
    minWidth: '7rem',
    padding: '1rem',
    paddingRight: '1rem',
    border: '2px solid black',
    borderRadius: '5px',
  },
  maxWidth30: {
    maxWidth: '30rem',
  },
}));
