import { makeStyles, Theme } from '@material-ui/core/';
import roadMarking from '../../../assets/img/roadMarkings/Road Markings@1x.png';

export const useStyles = makeStyles((theme: Theme) => ({
  coverSummaryContainer: {
    padding: '2rem 3rem 2rem 3rem',
    backgroundImage: `url(${roadMarking})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'calc(100% - 13%) calc(100% - 10px)',
    backgroundColor: theme.palette.primary.dark,
    '@media (max-width: 949px)': {
      backgroundPosition: 'calc(100% - 10%) calc(100% - 60px)',
    },
    '@media (max-width: 767px)': {
      backgroundPosition: 'calc(100% - 10%) calc(100% - 32px)',
      padding: '2rem 2.5rem 2rem 2.5rem',
    },
    '@media (max-width: 453px)': {
      backgroundPosition: 'calc(100% - 10%) calc(100% - 88px)',
    },
    '& h5': {
      color: theme.palette.common.white,
      marginBottom: '0.75rem',
      lineHeight: '2.188rem',
      ...theme.typography.h5,
      fontWeight: 500,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  greenHighlight: {
    color: theme.palette.green.main,
  },
  trustBoxContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    '@media (max-width: 767px)': {
      '& h5': {
        display: 'flex',
        order: 1,
      },
    },
    '@media (max-width: 975px)': {
      alignItems: 'flex-start',
      flexDirection: 'column-reverse',
    },
  },
  trustBox: {
    marginLeft: '-1.15rem',
    paddingTop: '2rem',
    '@media (min-width: 425px)': {
      marginLeft: '-1.15rem',
      maxHeight: '10rem',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
  desktopBreakpoint: {
    display: 'none',
    '@media (min-width: 767px)': {
      display: 'flex',
    },
  },
  mobileBreakPoint: {
    paddingTop: '1rem',
    display: 'block',
    '@media (min-width: 767px)': {
      display: 'none',
    },
  },
  headings: {
    fontSize: '2rem', // 32px
    lineHeight: '2.188rem', // 35px
    color: theme.palette.light[50],
  },
  subHeadings: {
    fontSize: '1.3125rem', // 21px
    lineHeight: '2.5rem', // 35px
    color: theme.palette.light[50],
  },
  overflowHidden: {
    overflow: 'hidden',
  },
}));
