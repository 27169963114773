import React from 'react';
import theme from './theme';
import './fonts.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StepProvider, steps, Step } from './contexts/StepContext';
import { CoverDetails, Review, ImportantInfo, Processing, FailedPayment, AllSorted, StyleGuide, FakeComparison, Timeout } from './pages';
import GlobalCss from './globalCss';
import { StylesProvider } from '@material-ui/core/styles';
// import { ErrorBoundary } from './components/atoms/ErrorBoundary';

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <CssBaseline />
          <GlobalCss />
          {/* <ErrorBoundary> potential before go live revert, need to discuss */}
          <StepProvider>
            <Switch>
              <Route exact path="/" component={CoverDetails} />
              <Route exact path="/european-cover/" component={CoverDetails} />
              <Route exact path="/coverOptions/:id" component={CoverDetails} />
              <Route exact path="/coverOptions/:id/:hash" component={CoverDetails} />
              <Route exact path={steps[Step.PERSONAL].url} component={Review} />
              <Route exact path={steps[Step.IMPORTANTINFO].url} component={ImportantInfo} />
              <Route exact path="/processing" component={Processing}/>
              <Route exact path="/cancel" component={Processing}/>
              <Route exact path="/failedpayment" component={FailedPayment}/>
              <Route exact path="/all-sorted" component={AllSorted}/>
              <Route exact path="/timeout" component={Timeout}/>
              {process.env.REACT_APP_INCLUDE_TEST_PAGES === 'true' && <Route exact path="/guide" component={StyleGuide} /> }
              {process.env.REACT_APP_INCLUDE_TEST_PAGES === 'true' && <Route exact path="/fakecomparison" component={FakeComparison} /> }
            </Switch>
          </StepProvider>
          {/* </ErrorBoundary> */}
        </StylesProvider>
      </ThemeProvider>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
