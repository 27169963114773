import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Theme,
} from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  accordionHeading: {
    margin: '0.5rem 0rem',
  },
  accordionBody: {
    fontSize: '1.3rem',
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      marginBottom: '0.75rem',
    },
    '& p:last-child': {
      marginBottom: '1rem',
    },
    '& a': {
      color: theme.palette.dark[800],
    },
  },
  expandMoreIcon: {
    fontSize: '1.75rem'
  },
  accordionSummary: {
    '& .Mui-expanded': {
      '& svg': {
        color: theme.palette.dark[900],
      }
    },
  },
}));

export const CSSAccordion = withStyles({
  root: {
    padding: '0px',
    borderTop: '2px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },

  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    padding: '0px',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(() => ({
  root: {
    padding: '0px 0px 1rem 0px',
  },
}))(MuiAccordionDetails);
