import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    helpIcon: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    infoIcon: {
      '&:hover': {
        cursor: 'pointer',
      },
      display: 'none',
    },
  }),
);
