import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    detailsCorrectHeading: {
      marginTop: '4rem',
      marginBottom: '3rem',
      maxWidth: '35rem',
    },
    actionButton: {
      paddingBottom: '5rem',
      marginTop: '1rem',
      '& button': {
        minWidth: '13rem',
        '@media (min-width: 321px)': {
          minWidth: '18rem',
        },
      },
      '@media (max-width: 410px)': {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        '& button': {
          margin: '1rem 0',
        },
      },
    },
    maxWidth30: {
      maxWidth: '30rem',
    },
    lineHeight: {
      lineHeight: '2rem',
    },
    mb3: {
      marginBottom: '3rem',
    },
    fw700: {
      fontWeight: 700,
    },
    dateHeadings: {
      paddingTop: '2rem',
      paddingBottom: '1rem',
    },
    DateField: {
      maxWidth: '18rem',
      width: '100%',
    },
    maxWidth18: {
      maxWidth: '18rem !important',
    },
    customDatePicker: {
      borderRadius: '2px',
      border: `2px solid ${theme.palette.dark[300]}`,
      backgroundColor: theme.palette.light[100],
      padding: '.75rem',
      minWidth: '18rem',
      '& .MuiInputBase-root': {
        '&:before': {
          borderBottom: 'none',
        },
        '&:hover': {
          borderBottom: 'none',
        },
        '&:focus': {
          borderBottom: 'none',
        },
      },
      '& $notchedOutline': {
        border: `2px solid ${theme.palette.dark[300]}`,
      },
      '&:hover $notchedOutline': {
        borderColor: theme.palette.common.black,
      },
      '&$focused $notchedOutline': {
        border: `2px solid ${theme.palette.light[100]}`,
      },
      '&$error $notchedOutline': {
        borderColor: theme.palette.utility.redLight,
        boxShadow: `inset 0 0 0 2px ${theme.palette.utility.redDark}`,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.utility.redLight}`,
        },
      },
    },
  }),
  { index: 1 },
);
