import React, { useState, useContext } from 'react';
import { useStyles } from './StyleGuideStyles';
import { StepContext } from '../../contexts/StepContext';
import { Grid, InputLabel, FormLabel, FormControl, Divider, Box } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import {
  ButtonPrimary,
  ButtonSecondary,
  RadioButtonGroup,
  RadioButtonTabs,
  TextField,
  DocumentTitle,
  Typography,
  SelectDropdown,
  Checkbox,
  DatePicker,
  Accordion,
  LinkPrimary,
  LinkSecondary,
  LinkNavigation,
  Notice,
  NextSteps,
  YourCoverReceipt,
  Tooltip
} from '../../components/atoms';
import { Modal, IModal, PostcodeLookup, CoverSummary } from '../../components/molecules';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import styleGuideSchema from './StyleGuideSchema.json';
import styleGuideYupSchema from './StyleGuideYupSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const StyleGuide: React.FC = (): JSX.Element => {
  const [errorBoundaryValue, setErrorBoundaryValue] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [showAddressFields, setShowAddressFields] = useState(false);

  const initialSchemaValues = {
    radioBtnTest: '',
    textFieldTest: '',
    textFieldMinMaxTest: 0,
    checkboxTest: false,
    dropdownTest: '',
    datePickerTest: null,
    vehicleLookup: [],
    vehicleCount: 0,
    vehicleId: '',
    postcodeLookup: {
      firstLineOfAddress: '',
      secondLineOfAddress: '',
      town: '',
      county: '',
      postcode: '',
    },
  };

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: {
      isValid,
      errors,
    },
    trigger,
    watch,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(styleGuideYupSchema),
    defaultValues: initialSchemaValues,
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const context = useContext(StepContext);
  const { activeStep, updateActiveStep, steps, showStepper, updateShowStepper } = context;
  const {
    radioBtnGroupData,
    accordionData,
    dropdownData,
    radioBtnTabsData,
    modalData,
    noticeData,
  } = styleGuideSchema;

  const onSubmit = (data: any) => alert(JSON.stringify(data, null, 2));
  const handleBack = () => updateActiveStep(activeStep - 1);
  const handleNext = () => updateActiveStep(activeStep + 1);
  const handleShowPostcodeFields = () => setShowAddressFields(true);
  const showErrorBoundary = () => setErrorBoundaryValue(1);
  const handleToggleStepper = () => updateShowStepper(!showStepper);
  const plPostcode = watch('postcodeLookup.postcode') as string;
  const classes = useStyles();

  if (errorBoundaryValue === 1) throw new Error('error boundary');

  return (
    <PageTemplate>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <DocumentTitle title="Style Guide" />
          <Box className={classes.nextStepBtnContainer}>
            {activeStep === steps.length ? (
              <ButtonPrimary onClick={() => alert('Submitted')}>Submit</ButtonPrimary>
            ) : (
              <ButtonPrimary onClick={handleNext}>Next Step</ButtonPrimary>
            )}
            <ButtonSecondary disabled={activeStep === 1} onClick={handleBack}>
              Back
            </ButtonSecondary>
          </Box>
          <Divider className="divider"></Divider>

          <Box className={classes.btnContainer}>
            <ButtonPrimary buttonType="light" className={classes.btn}>
              Label
            </ButtonPrimary>
            <ButtonSecondary className={classes.btn}>Label</ButtonSecondary>
            <Box className={classes.linkContainer}>
              <LinkPrimary>This is a primary link</LinkPrimary>
              <LinkSecondary>This is a secondary link</LinkSecondary>
              <LinkNavigation>This is a journey navigation link</LinkNavigation>
            </Box>
            <form className={classes.formContainer} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Radio Button Label</FormLabel>
                <RadioButtonGroup
                  ariaLabel="radio button group"
                  control={control}
                  name="radioBtnTest"
                  data={radioBtnGroupData}
                  watch={watch}
                />
              </FormControl>
              {errors.radioBtnTest && <p className={classes.error}>{errors.radioBtnTest.message}.</p>}
              <Box className={classes.textFieldContainer}>
                <InputLabel className={classes.inputLabel} htmlFor="textFieldTest">
                  TextField
                </InputLabel>
                <TextField id="textFieldTest" name="textFieldTest" control={control} />
                {errors.textFieldTest && <p className={classes.error}>{errors.textFieldTest.message}</p>}
                <Box>
                  <InputLabel className={classes.inputLabel} htmlFor="textFieldMinMaxTest">
                    TextField Min Max
                  </InputLabel>
                  <TextField
                    name="textFieldMinMaxTest"
                    control={control}
                    id="textFieldMinMaxTest"
                    type="number"
                    inputProps={{ min: 0, max: 10, step: 1 }}
                  />
                  {errors.textFieldMinMaxTest && <p className={classes.error}>{errors.textFieldMinMaxTest.message}</p>}
                </Box>
              </Box>
              <Box className={classes.checkboxContainer}>
                <FormControl component="fieldset">
                  <Checkbox
                    name="checkboxTest"
                    watch={watch}
                    control={control}
                    trigger={trigger}
                    label="Button Label"
                    labelDescription="Lorem ipsum dolor sit amet"
                    color="primary"
                    error={errors.checkboxTest ? true : false}
                  />
                </FormControl>
                <FormControl component="fieldset"></FormControl>
              </Box>
              {errors.checkboxTest && <p className={classes.error}>{errors.checkboxTest.message}</p>}
              <Box className={classes.genericContainer}>
                <Accordion heading={accordionData.heading} body={<Typography>Test</Typography>} />
              </Box>
              <SelectDropdown
                name="dropdownTest"
                control={control}
                variant="outlined"
                defaultValue=""
                data={dropdownData}
              />
              {errors.dropdownTest && <p className={classes.error}>{errors.dropdownTest.message}</p>}
              <Box className={classes.datePickerContainer}>
                <InputLabel htmlFor="datePickerTest">Date Picker</InputLabel>
                <DatePicker
                  className={classes.datePicker}
                  id="datePickerTest"
                  name="datePickerTest"
                  control={control}
                />
                {errors.datePickerTest && <p className={classes.error}>{errors.datePickerTest.message}</p>}
              </Box>
              <Box className={classes.postcodeLookupContainer}>
                <Typography className={classes.postcodeLookupContainerField} variant="h5">
                  Postcode Lookup
                </Typography>
                <Grid container alignItems="baseline" justify="flex-start" direction="row">
                  {showAddressFields ? (
                    <>
                      <Grid item xs={3}>
                        <InputLabel className={classes.inputLabel} htmlFor="postcode">
                          FIRST LINE OF ADDRESS
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          className={classes.postcodeLookupContainerField}
                          id="postcodeLookup.firstLineOfAddress"
                          name="postcodeLookup.firstLineOfAddress"
                          control={control}
                        />
                        {errors.postcodeLookup?.firstLineOfAddress && (
                          <p className={classes.error}>{errors.postcodeLookup?.firstLineOfAddress.message}</p>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel className={classes.inputLabel} htmlFor="postcode">
                          SECOND LINE OF ADDRESS
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          className={classes.postcodeLookupContainerField}
                          defaultValue={watch('postcodeLookup.secondLineOfAddress')}
                          id="postcodeLookup.secondLineOfAddress"
                          name="postcodeLookup.secondLineOfAddress"
                          control={control}
                        />
                        {errors.postcodeLookup?.secondLineOfAddress && (
                          <p className={classes.error}>{errors.postcodeLookup?.secondLineOfAddress.message}</p>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel className={classes.inputLabel} htmlFor="postcode">
                          TOWN
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          className={classes.postcodeLookupContainerField}
                          id="postcodeLookup.town"
                          name="postcodeLookup.town"
                          control={control}
                        />
                        {errors.postcodeLookup?.town && (
                          <p className={classes.error}>{errors.postcodeLookup?.town.message}</p>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel className={classes.inputLabel} htmlFor="postcode">
                          COUNTY
                        </InputLabel>
                      </Grid>
                      <Grid item xs={9}>
                        <TextField
                          className={classes.postcodeLookupContainerField}
                          id="postcodeLookup.county"
                          name="postcodeLookup.county"
                          control={control}
                        />
                        {errors.postcodeLookup?.county && (
                          <p className={classes.error}>{errors.postcodeLookup?.county.message}</p>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel className={classes.inputLabel} htmlFor="postcode">
                          POSTCODE
                        </InputLabel>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={9}>
                    <TextField
                      className={classes.postcodeLookupContainerField}
                      placeholder="Postcode"
                      id="postcodeLookup.postcode"
                      name="postcodeLookup.postcode"
                      control={control}
                    />
                    {errors.postcodeLookup?.postcode && (
                      <p className={classes.error}>{errors.postcodeLookup?.postcode.message}</p>
                    )}
                  </Grid>
                </Grid>
                <PostcodeLookup
                  onClick={handleShowPostcodeFields}
                  name="postcodeLookup"
                  setValue={setValue}
                  postcode={plPostcode}
                  trigger={trigger}
                />
              </Box>
              <Box className={classes.actionBtnsContainer}>
                <ButtonPrimary disabled={!isValid} className={classes.submitBtn} type="submit">
                  Submit
                </ButtonPrimary>
                <ButtonPrimary onClick={() => reset()}>Reset</ButtonPrimary>
              </Box>
            </form>
          </Box>
          <Box>
            <Box className={classes.genericContainer}>
              <RadioButtonTabs data={radioBtnTabsData} />
            </Box>
            <Modal
              open={modalOpen}
              hasCallUsLink={false}
              hasFooter={false}
              setOpen={setModalOpen}
              componentType={modalData.type as IModal['componentType']}
              heading={modalData.heading}
              body={modalData.body}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className="mt2">
          <Notice heading={noticeData.heading} message={noticeData.message} messageType="error" />
        </Grid>
        <Grid item xs={12} className="mt2">
          <Typography>Tooltip</Typography>
          <Tooltip title={<Typography variant="body2">Tooltip contents</Typography>} arrow>
            <InfoOutlinedIcon />
          </Tooltip>
        </Grid>
        <Grid item xs={12} lg={8} className="mt2">
          <ButtonPrimary onClick={showErrorBoundary}>React Error</ButtonPrimary>
        </Grid>
        <Grid item xs={12} className="mt2">
          <ButtonSecondary onClick={handleToggleStepper}>
            {showStepper ? 'Hide Stepper' : 'Show Stepper'}
          </ButtonSecondary>
        </Grid>
      </Grid>
      <Box maxWidth={"70%"}>
        <CoverSummary
        tripType="Annual"
        coverPrice={123.45}
        policyStartDate="12/12/2022"
        policyEndDate="12/12/2022"
        />
      </Box>
      <Grid container className={classes.gridMainContainer}>
        <YourCoverReceipt startDate="2022/09/30" endDate="2023/09/29" />
      </Grid>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8}>
          <NextSteps />
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default StyleGuide;
