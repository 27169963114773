import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    field: {
      ...theme.form.labels?.selectFieldSmall.label,
      fontSize: '1.125rem',
      fontWeight: 'normal',
    },
    fieldValue: {
      ...theme.form.labels?.selectFieldSmall.label,
    },
    address: {
      ...theme.form.labels?.selectFieldSmall.label,
      fontWeight: 400,
    },
    yourDetailsHeading: {
      fontWeight: 500,
      marginBottom: '1rem',
    },
    yourDetailsContainer: {
      marginBottom: '2rem',
      '& div': {
        marginBottom: '0.5rem',
      },
    },
    overflowHidden: {
      overflow: 'hidden',
    },
  }),
  { index: 1 },
);
