const ANNUAL_UPPER = 'ANNUAL';
const ANNUAL = 'Annual';
const TOWING_INTERNAL = ['Towing'];
const TOWING = 'Towing a caravan';
const TOWING_UPPER = 'TOWING A CARAVAN';
const SINGLE_TRIP_INTERNAL = 'Continental';

export const buildInternalProductNameString = (incomingProductName: string) : { baseOption; additionalOptions } => {
  const productNameParts = incomingProductName.split('+').map(part => part.trim());
  let additionalOption = [''];
  let internalProductName = '';

  let hasTowing=false;
  if(productNameParts.some(part => part.toUpperCase() === TOWING || part.toUpperCase() === TOWING_UPPER)) {
    hasTowing = true;
  }
  let isAnnual=false;
  if(productNameParts.some(part => part.toUpperCase() === ANNUAL || part.toUpperCase() === ANNUAL_UPPER)) {
      isAnnual = true;
  }

  if (hasTowing) {
    additionalOption = TOWING_INTERNAL;
  } else {
    additionalOption = [''];
  }

  if (isAnnual) {
    internalProductName = ANNUAL;
  } else {
    internalProductName = SINGLE_TRIP_INTERNAL;
  }

  return {
    baseOption: internalProductName,
    additionalOptions: additionalOption
  };
};
