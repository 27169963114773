import React from 'react';
import { Typography } from '../../components/atoms';

const getDocument = (filePath: string) => window.open(filePath);

export const reviewSchema = {
  rightToCancel: {
    heading: 'Your right to cancel',
    body: (
      <>
        <Typography>
          You can cancel your policy any time, we’ll give you a refund based on how long your policy had left and
          providing you have not made a claim, less an administrative fee of £10.60 (inclusive of Insurance Premium Tax
          where applicable). If you cancel within 14 days of the policy starting or of you receiving the documents,
          whichever is later (this is the cooling off period), we’ll give you a full refund as long as you have not made
          a claim during that time or started your trip.
        </Typography>
        <Typography>
          We’ve got the right to cancel your policy at any time. We’ll give you 14 days notice in writing to the most
          recent address that you’ve given us. We’ll give you back what you paid, apart from an amount for the time that
          your policy lasted.
        </Typography>
      </>
    ),
  },
  complaints: {
    heading: 'Complaints',
    body: (
      <>
        <Typography>
          If you’re not happy about something, please let us know. The quickest way is to call us. For complaints to do
          with a breakdown, call us on 0800 051 0636.
        </Typography>
        <Typography>
          For complaints about anything else, contact us on 0800 400 638. You can also write to us at the address shown
          in your policy document which gives more details about how to make a complaint.
        </Typography>
        <Typography>
          If we cannot sort out the differences between you and us, you can take the matter to the Financial Ombudsman
          Service (FOS). Their address is: The Financial Ombudsman Service, Exchange Tower, London E14 9SR. Phone: 0800
          023 4567 or 0300 123 9123. You can visit the FOS website at{' '}
          <a href="https://www.financial-ombudsman.org.uk/" target="_blank" rel="noreferrer">
            www.financial-ombudsman.org.uk
          </a>
        </Typography>
        <Typography>Keep in mind, your cover is subject to the standard policy wording.</Typography>
      </>
    ),
  },
  financialCompensation: {
    heading: 'Financial Services Compensation Scheme',
    body: (
      <>
        <Typography>
          General insurance claims are covered by the Financial Services Compensation Scheme. Full details of the cover
          available can be found here. U K Insurance Limited is a member of this scheme.
        </Typography>
      </>
    ),
  },
  informationUsage: {
    heading: "How we'll use your information",
    body: (
      <>
        <Typography>
          We will use your information and that of any other people named on your policy to assess your breakdown cover
          application and the terms on which cover may be offered. We may exchange your information with other insurers,
          reinsurers or other carefully selected third parties in order to manage your policy and offer you the best
          possible service. We will also obtain information about you from credit reference agencies to check your
          credit status and identity. The agencies record our enquiries but your credit standing will not be affected.
        </Typography>
        <Typography>
          Green Flag breakdown policies are underwritten by U K Insurance Limited (UKI). The information you give us may
          be used by owned brands within UKI to submit to and check your details with fraud prevention agencies and
          other organisations. If you provide false or inaccurate information, we will record this. We and other
          organisations may use and search these records to prevent fraud and money laundering.
        </Typography>
        <Typography>
          If you would like full details on how we will use your information, please refer to our{' '}
          <a href="https://u-k-insurance.co.uk/brands-policy.html" target="_blank" rel="noreferrer">
            privacy policy
          </a>{' '}
          or the{' '}
          <a
            href="#"
            className="anchorLink"
            onClick={() => getDocument('pdfs/European-Policy.pdf')}
            rel="noreferrer"
          >
            policy booklet
          </a>
          .
        </Typography>
      </>
    ),
  },
  englishLawApplies: {
    heading: 'English law applies',
    body: (
      <>
        <Typography>
          You and we may choose which law will apply to this policy. Unless both parties agree otherwise English law
          will apply. We have supplied this policy and other information to you in English and we will continue to
          communicate with you in English.
        </Typography>
      </>
    ),
  },
  PaymentModalData:{
    heading: "Time for a quick detour.",
    body: "We're taking you to Stripe, our third-party payment provider.",
  },
};

export default reviewSchema;
