import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[4],
    color: theme.palette.dark[800],
    maxWidth: '20.5rem',
    padding: 0,
  },
  arrow: {},
}))(Tooltip);

export const useStyles = makeStyles(
  (theme: Theme) => ({
    tooltipGreenLine: {
      position: 'absolute',
      top: '-1px',
      height: '8px',
      width: '100%',
      borderRadius: '0px',
      backgroundColor: theme.palette.green[700],
    },
    tooltipContent: {
      padding: '2rem',
    },
    arrow: {
      fontSize: '1.5rem',
      color: theme.palette.green[700],
    },
  }),
  { index: 1 },
);
