export const getPremiumType = (productName: string): string => {
  return productName.toLowerCase().includes('european-cover') ? 'BEU' : 'BUK';
};

export const getProviderID = (source: string): number => {
  switch (source.toUpperCase()) {
    case 'MS':
      return 115;
    case 'CT':
      return 117;
    case 'GO':
      return 116;
    case 'RC':
      return 48;
    case 'MO':
      return 372;
    case 'US':
      return 629;
    default:
      //Octal literals are not allowed in strict mode" workaround.
      return parseInt('000');
  }
};

export const getSourceCode = (source: string): number => {
  switch (source.toUpperCase()) {
    case 'MS':
      return 1;
    case 'CT':
      return 3;
    case 'GO':
      return 2;
    case 'RC':
      return 4;
    case 'MO':
      return 6;
    case 'US':
      return 7;
    default:
      return 0;
  }
};

export const isMobile = (): boolean => {
  let hasTouchScreen = false;
  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    //@ts-ignore
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    //@ts-ignore
    const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  return hasTouchScreen;
};
