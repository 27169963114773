import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    vehicleInformation: {
      padding: '0.4rem',
      backgroundColor: theme.palette.utility.amberLight,
      '@media (max-width: 625px)': {
        marginTop: '1rem',
      },
    },
    vehicleInformationList: {
      display: 'flex',
      listStyleType: 'none',
      paddingLeft: '0.5rem',
      '@media (max-width: 840px)': {
        flexDirection: 'column',
      },
    },
    vehicleInformationEntry: {
      fontFamily: 'Oswald',
      paddingRight: '1rem',
      '@media (max-width: 840px)': {
        marginBottom: '1rem',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    vehicleInformationConfirm: {
      padding: '1rem',
    },
    confirmHeading: {
      marginBottom: '1rem',
      fontWeight: 700,
    },
    vehicleInfoForm: {
      marginTop: '1rem',
    },
    vehicleInfoFormHeader: {
      fontWeight: 700,
      marginBottom: '1.5rem',
    },
    vehicleInfoFormGrid: {
      display: 'flex',
      alignItems: 'center',
    },
    btnMargin: {
      marginLeft: '1rem',
    },
    bold: { fontWeight: 700 },
    errorText: {
      color: theme.palette.error.main,
      margin: 0,
      padding: '1rem 0 0 0',
    },
    vehicleDetailsSubmitBtn: {
      display: 'flex',
      '@media (max-width: 600px)': {
        marginTop: '1rem',
      },
    },
    inputFields: {
      width: '20rem',
    },
    vehicleInfoFormDropdown: {
      width: '100%',
    },
    maxWidth20: {
      maxWidth: '20rem',
    },
  }),
  { index: 1 },
);
