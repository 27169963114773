import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

import PageTemplate from '../../templates/PageTemplate';
import { DocumentTitle } from '../../components/atoms';
import { StepContext, steps, Step } from '../../contexts/StepContext';
import { useStyles } from './ProcessingStyles';

export const Processing: React.FC = () => {
  const { updateShowStepper, updateData, updateActiveStep } = useContext(StepContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id');

  useEffect(() => {
    if (!sessionId) {
      history.replace('/');
    }
    window.scrollTo(0, 0);
    updateShowStepper(false);
    if (location.pathname === '/cancel/') {
      expire();
    } else {
      ingest();
    }
  }, []);

  const retrieveQuoteData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${
          process.env.REACT_APP_RETRIEVE_QUOTE_ENDPOINT
        }/${sessionId}/${Cookies.get('GFSessionControl')}`,
      );


      if (response.data.err) {
        throw new Error(response.data.err);
      }

      const retrievedData = response.data.data;

      const quoteDataReactFormat = {
        quote: retrievedData.quote_id,
        source: retrievedData.origin_site,
        title: retrievedData.customer.title,
        firstName: retrievedData.customer.first_name,
        lastName: retrievedData.customer.last_name,
        dateOfBirth: retrievedData.customer.date_of_birth,
        emailAddress: retrievedData.customer.email,
        phoneNumber: retrievedData.customer.telephone,
        addressLine1: retrievedData.customer.address.house,
        addressLine2: retrievedData.customer.address.street,
        addressLine3: retrievedData.customer.address.town,
        addressLine4: '',
        addressLine5: '',
        postcode: retrievedData.customer.address.postcode,
        vehicleType: '',
        registrationNumber: retrievedData.vehicle.registration,
        vehicleMake: retrievedData.vehicle.make,
        vehicleModel: retrievedData.vehicle.model,
        vehicleAge: retrievedData.vehicle_age_yrs,
        productName: retrievedData.product_name,
        caravanTowCheck: retrievedData.cover_options.additional_options.includes('Towing') ? 'true' : 'false',
        baseOption: retrievedData.cover_options.base_option,
        coverPrice: retrievedData.Premium,
        policyStartDate: retrievedData.start_date,
        policyEndDate: retrievedData.end_date,
        automaticRenewal: retrievedData.continuous,
        coverType: retrievedData.cover_options.base_option,
        additionalOption: retrievedData.cover_options.additional_options,
        tripType: retrievedData.cover_options.base_option,
        checkoutId: sessionId,
        quoteHash: Cookies.get('GFSessionControl'),
      };

      const stateDataObject = await getPricingData(quoteDataReactFormat);
      updateData(stateDataObject);
      return quoteDataReactFormat;
    } catch (error: any) {
      window.location.replace('/');
    }
  };

  const getPricingData = async (quoteData) => {
    try {
      const { data: options } = await axios.get(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_OPTIONS_ENDPOINT}`,
      );

      const stateDataObjectInternal = {
        ...quoteData,
        options: options.Items,
      };
      return stateDataObjectInternal;
    } catch (err) {
      window.location.replace('/failedpayment');
    }
  };

  const ingest = async () => {
    const quote = await retrieveQuoteData();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_INGEST_ENDPOINT}`,
        {
          checkoutId: quote?.checkoutId,
        },
      );

      if (response.status === 200) {
        history.push('/all-sorted');
      } else {
        history.push('/failedpayment');
      }
    } catch {
      history.push('/failedpayment');
    }
  };

  const expire = async () => {
    const quote = await retrieveQuoteData();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_EXPIRE_CHECKOUT_ENDPOINT}`,
        {
          checkoutId: quote?.checkoutId,
        },
      );

      updateActiveStep(3);
      history.push(steps[Step.IMPORTANTINFO].url);
    } catch (err) {
      updateActiveStep(3);
      history.push(steps[Step.IMPORTANTINFO].url);
    }
  };

  return (
    <PageTemplate>
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Processing`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} sm={5} lg={6} className={classes.gridMain}>
          <Typography className={classes.heading} variant="h1">
            ONE SECOND...
          </Typography>
          <Typography className="pt1">Please don&apos;t close this window or click back in the browser.</Typography>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Processing;
