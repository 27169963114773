import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    noticeContainer: {
      maxWidth: '35rem',
      padding: '.75rem .75rem',
    },
    default: {
      border: `2px solid ${theme.palette.light[400]}`,
      backgroundColor: theme.palette.light[100],
    },
    error: {
      border: `2px solid ${theme.palette.utility.redLight}`,
      backgroundColor: theme.palette.utility.noticeError,
    },
    heading: {
      ...theme.form.labels.inputField.label,
      marginBottom: '0.3rem',
    },
  }),
  { index: 1 },
);
