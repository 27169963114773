import React, { useContext, useState } from 'react';
import { useStyles } from './StateEditorStyles';
import { StepContext } from '../../../contexts/StepContext';
import { Modal } from '../../../components/molecules';
import { StateEditorModalBody } from './StateEditorModalBody';
import EditIcon from '@material-ui/icons/Edit';

export const DLG_DEBUG_STATE_EDITOR_ENABLED = `DLG_Debug_${process.env.REACT_APP_SITE_ID || ''}_StateEditor_Enabled`;
export const DLG_DEBUG_STATE_EDITOR_OVERRIDE = `DLG_Debug_${process.env.REACT_APP_SITE_ID || ''}_StateEditor_Override`;
export const DLG_DEBUG_STATE_EDITOR_DATA = `DLG_Debug_${process.env.REACT_APP_SITE_ID || ''}_StateEditor_Data`;
export const DLG_DEBUG_STATE_EDITOR_REDIRECT = `DLG_Debug_${process.env.REACT_APP_SITE_ID || ''}_StateEditor_Redirect`;

const StateEditor: React.FC = () => {
  const context = useContext(StepContext);
  const { activeStep, steps } = context;
  const [enableRedirect, setEnableRedirect] = useState(
    localStorage.getItem(DLG_DEBUG_STATE_EDITOR_REDIRECT)
      ? localStorage.getItem(DLG_DEBUG_STATE_EDITOR_REDIRECT) === 'true'
      : false,
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const classes = useStyles();

  const initialise = (activeStep: number): void => {
    const stateEditorEnabled = JSON.parse(localStorage.getItem(DLG_DEBUG_STATE_EDITOR_ENABLED) as any);
    const redirectEnabled: boolean = JSON.parse(localStorage.getItem(DLG_DEBUG_STATE_EDITOR_REDIRECT) as any);
    if (stateEditorEnabled && redirectEnabled) {
      const savedUrl = window.location.origin + steps[activeStep - 1].url;
      const urlExceptions = ['/guide', '/fakecomparison'];
      if (window.location.href !== savedUrl && urlExceptions.includes(window.location.pathname) === false) {
        window.location.href = savedUrl;
      }
    }
  };

  return (
    <>
      {initialise(activeStep)}
      <EditIcon className={classes.icon} onClick={() => setShowEditModal(true)} />
      <Modal
        heading="Edit State"
        hasCallUsLink={false}
        hasFooter={false}
        open={showEditModal}
        setOpen={setShowEditModal}
        onClose={() => setShowEditModal(false)}
        componentType="fragment"
        body={
          <StateEditorModalBody
            enableRedirect={enableRedirect}
            setEnableRedirect={setEnableRedirect}
            setShowEditModal={setShowEditModal}
          />
        }
      />
    </>
  );
};

export default StateEditor;
